import React, { Component } from 'react';
import buddylogo from '../../assets/images/buddy-the-ball-logo.webp';

class Footer extends Component{
  render () {
    return (
      <div className="container max-w-screen-lg mx-auto">
        <a href="https://buddytheball.net/" target="_blank" rel="noopener noreferrer" className="block float-right" alt="Buddy The Ball" title="Buddy The Ball">
          <img src={buddylogo} alt="Buddy The Ball Logo" />
        </a>
      </div>
    );
  }
}

export default Footer;