import { combineReducers } from 'redux';
import user from './user';
import league from './league';
import leagues from './leagues';
import roster from './roster';
import players from './players';
import flashMessages from './flashMessages';
import leagueJoin from './leagueJoin';

const reducers = {
  user,
  league,
  roster,
  leagues,
  players,
  flashMessages,
  leagueJoin
};

export default combineReducers(reducers);