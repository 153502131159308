import React from "react";
import FlashMessage from './FlashMessage';
import { connect } from "react-redux";
import { DELETE_FLASH_MESSAGE } from '../../redux/actions/actionTypes';

class FlashMessageList extends React.Component {
  render() {
    const messages = this.props.messages.map(message => 
        <FlashMessage key={message.id} message={message} deleteFlashMessage={this.props.deleteFlashMessage} />
        );
    return <div>{messages}</div>;
  }
}


const mapDispatchToProps = dispatch => {
    return {
      deleteFlashMessage: (id) => dispatch({type: DELETE_FLASH_MESSAGE, id})
    };
  };

function mapStateToProps(state) {
    return {
        messages: state.flashMessages
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessageList);
