import { put, takeEvery } from 'redux-saga/effects';
import {
  SET_LEAGUE,
  SET_LEAGUES,
  GET_LEAGUE,
  GET_LEAGUES,
  GET_PLAYERS,
  SET_WTA_PLAYERS,
  SET_ATP_PLAYERS,
  SET_PLAYERS,
  GET_AUTH_TOKEN,
  SET_USER,
  GET_CURRENT_USER,
  SET_USER_LOADING,
  CREATE_USER,
  DROP_PLAYER,
  ADD_PLAYER,
  SWAP_PLAYERS,
  CREATE_LEAGUE,
  ADD_FLASH_MESSAGE,
  START_DRAFT,
  EDIT_LEAGUE,
  SUBMIT_PICK_BID,
  APPEND_ATP_PLAYERS,
  APPEND_WTA_PLAYERS,
  APPEND_PLAYERS,
  DELETE_LEAGUE,
  SET_USER_SHOULD_LOGIN,
  LEAGUE_JOIN,
  SET_LEAGUE_JOIN,
  LEAGUE_LEAVE,
  DELETE_ACCOUNT,
  REMOVE_USER,
  UPDATE_ACCOUNT,
  REVERSE_PICK,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  SUBMIT_EXPERT_BID
} from '../actions/actionTypes';
import { api, removeTokens, TOKENS_STORAGE_KEY } from '../../utils/api';

function* getLeagues() {
  var leagues;
  yield api.get('/leagues/')
    .then(response => {
      leagues = response.data;
    });
  yield put({ type: SET_LEAGUES, payload: leagues});
}

function* getLeague(action) {
  var league;
  yield api.get('/leagues/' + action.payload + '/')
    .then(response => {
      league = response.data;
    });

  if (league.atp_players) {
    yield put({type: GET_PLAYERS, filters: {league_org: 'ATP', limit: 25, exclude_league_picks: league.id}});
  }
  if (league.wta_players) {
    yield put({type: GET_PLAYERS, filters: {league_org: 'WTA', limit: 25, exclude_league_picks: league.id}});
  }

  yield put({ type: SET_LEAGUE, payload: league});

}

function* leagueJoin(action) {
  var success = false;

  yield api.get('/leagues/' + action.payload.leagueCode + '/join/')
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error.response.data);
    });
  yield put({ type: SET_LEAGUE_JOIN, payload: {success: success, loading: false}});
}

function* leagueLeave(action) {
  var success = false;

  yield api.get('/leagues/' + action.leagueId + '/leave/')
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error.response.data);
    });

  if(success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Successfully left league.'}});
    yield put({ type: GET_LEAGUES });
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Failed to leave league.'}});
  }
}

function* getPlayers(action) {
  var setActionType;
  var players = [];
  var url = '/players/';

  if (action.filters && action.filters.season) {
    url += '?season=' + action.filters.season;
  } else {
    let d = new Date();
    url += '?season=' + d.getFullYear();
  }

  if (action.filters && action.filters.limit) {
    url += '&limit=' + action.filters.limit;
  }

  if (action.filters && action.filters.offset) {
    url += '&offset=' + action.filters.offset;
  }

  var hasOffset = action.filters.offset != null && action.filters.offset > 0;

  if (action.filters && action.filters.league_org) {
    url += '&league_org=' + action.filters.league_org;
    if (action.filters.league_org === 'ATP') {
      if (hasOffset) {
        setActionType = APPEND_ATP_PLAYERS;
      } else {
        setActionType = SET_ATP_PLAYERS;
      }
    } else {
      if (hasOffset) {
        setActionType = APPEND_WTA_PLAYERS;
      } else {
        setActionType = SET_WTA_PLAYERS;
      }
    }
  } else {
    if (hasOffset) {
      setActionType = SET_PLAYERS;
    } else {
      setActionType = APPEND_PLAYERS;
    }
  }

  if (action.filters && action.filters.exclude_league_picks) {
    url += '&exclude_league_picks=' + action.filters.exclude_league_picks;
  }

  if (action.filters && action.filters.search) {
    url += '&search=' + action.filters.search;
  }

  yield api.get(url)
    .then(response => {
      players = response.data;
    });

  yield put({ type: setActionType, payload: players});
}

function* getCurrentUser(action) {
  var user;
  yield put({ type: SET_USER_LOADING, loading: true});

  yield api.get('/users/current-user/')
    .then(response => {
      user = response.data;
    })
    .catch(error => {
      console.log(error.response.data);
    });

  if (user) {
    yield put({ type: SET_USER, payload: user});
  }

  yield put({ type: SET_USER_LOADING, loading: false});
}

function* getTokens(action) {
  var success = false;
  var errorMessage;

  yield api.post('/token/', action.payload)
    .then(response => {
      localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(response.data));
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data.detail;
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'You have successfully logged in'}});
    yield put({ type: GET_CURRENT_USER });
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: errorMessage}});
  }
}

function* createUser(action) {
  var user;
  var errorMessages;

  yield api.post('/users/create/', {
    email: action.payload.email,
    first_name: action.payload.firstName,
    last_name: action.payload.lastName,
    password: action.payload.password
  })
    .then(response => {
      user = response.data;
    })
    .catch(error => {
      errorMessages = error.response.data;
    });

  if (user) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Account successfully created! You can now login.'}});
    yield put({ type: SET_USER_SHOULD_LOGIN, userShouldLogin: true });
  } else {
    if (errorMessages && errorMessages.email){
      yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: errorMessages.email}});
    } else{
      yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Failed to create account. Contact an admin.'}});
    }
  }
}

function* dropPlayer(action) {
  yield put({ type: GET_LEAGUE, payload: action.payload.league_id});
  yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'You have successfully dropped a player.'}});
}

function* addPlayer(action) {
  var success;
  var errorMessage;

  yield api.patch(`/rosters/${action.payload.roster.id}/add-player/`, {player_id: action.payload.player.id})
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data;
    });
  yield put({ type: GET_LEAGUE, payload: action.payload.league.id});

  if (success) {
    yield put({
      type: ADD_FLASH_MESSAGE,
      message: {
        type: 'success',
        text: `You have successfully added ${action.payload.player.name} to ${action.payload.roster.user.first_name}'s roster.`
      }
    });
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
  }
}

function* swapPlayers(action) {
  var success;
  var errorMessage;

  yield api.patch(`/rosters/${action.payload.rosterId}/swap-player/`, {
    current_player_id: action.payload.currentPlayer.id,
    new_player_id: action.payload.newPlayer.id
  })
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data;
    });


  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: `You have swapped ${action.payload.currentPlayer.name} with ${action.payload.newPlayer.name}.`}});
    yield put({ type: GET_LEAGUE, payload: action.payload.leagueId});
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
  }
}

function* createLeague(action) {
  var success = false;
  yield api.post('/leagues/', action.payload)
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'League successfully created'}});
    yield put({ type: GET_LEAGUES });

  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'League failed to be created.'}});
  }
}

function* editLeague(action) {
  var success = false;
  var errorMessage;

  yield api.put(`/leagues/${action.leagueId}/`, action.payload)
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data;
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'League successfully updated'}});
    yield put({ type: GET_LEAGUES });
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: errorMessage}});
  }
}

function* startLeague(action) {
  var success = false;
  var errorMessage;

  if (action.payload.rosters.length < 2){
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'A league requires a minimum of two rosters.'}});
    return;
  }

  yield api.patch(`/leagues/${action.payload.id}/`, {
    league_status: 'draft',
  })
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data;
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Draft has now started.'}});
    yield put({ type: GET_LEAGUE, payload: action.payload.id});
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
  }
}

function* submitPickBid(action) {
  var success = false;
  var errorMessage;

  yield api.patch(`/rosters/${action.payload.rosterId}/`, action.payload)
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
      success = false;
      errorMessage = error.response.data;
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Bid successfully submitted.'}});
    yield put({ type: GET_LEAGUE, payload: action.payload.leagueId});
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
  }
}

function* deleteLeague(action) {
  var success = false;
  var errorMessage;


  yield api.delete(`/leagues/${action.leagueId}/`)
    .then(() => {
      success = true;
    })
    .catch(error => {
      success = false;
      errorMessage = error.response.data;
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'League successfully deleted.'}});
    yield put({ type: GET_LEAGUES });
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
  }
}

function* deleteAccount(action) {
  var success = false;

  yield api.delete('/users/delete/')
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error.response.data);
    });

  if (success) {
    removeTokens();
    yield put({ type: REMOVE_USER });
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Your account has been deleted.'}});
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Unable to delete account.'}});
  }
}

function* updateAccount(action) {
  var user;
  var payload = {
    email: action.payload.email,
    first_name: action.payload.firstName,
    last_name: action.payload.lastName,
  };

  if (action.payload.password) {
    payload.password = action.payload.password;
  }

  yield api.patch('/users/current-user/', payload)
    .then((response) => {
      user = response.data;
    })
    .catch(error => {
      console.log(error.response.data);
    });

  if (user) {
    yield put({ type: SET_USER, payload: user});
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Your account has been updated!'}});
  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Unable to update your account.'}});
  }
}

function* reversePick(action) {
  var success = false;
  yield api.get(`/leagues/${action.payload.id}/revert-pick/`)
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Reversed one draft pick.'}});
    yield put({ type: GET_LEAGUE, payload: action.payload.id });

  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Unable to reverse draft pick'}});
  }
}

function* requestResetPassword(action) {
  var success = false;
  yield api.post(`/users/request-reset-password/`, action.payload)
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Password reset request submitted. Please check your email.'}});

  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Failed to submit password reset request. Please contact an admin.'}});
  }
}

function* resetPassword(action) {
  var success = false;
  yield api.post(`/users/reset-password/`, {password_reset_token: action.payload.resetToken, password: action.payload.password})
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
    });

  if (success) {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Password reset was successful! You may now login with your new password.'}});

  } else {
    yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Failed to submit password reset. Check that your reset token is valid.'}});
  }
}

function* submitExpertBid(action) {
  var success = false;
  var errorMessage;

  yield api.post(`/rosters/${action.payload.rosterId}/expert-bid/`, action.payload)
    .then(() => {
      success = true;
    })
    .catch(error => {
      console.log(error);
      success = false;
      errorMessage = error.response.data;
    });

  if (success) {
    // yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'Bid successfully submitted.'}});
    console.log(success)
    yield put({ type: GET_LEAGUE, payload: action.payload.leagueId});
  } else {
    // yield put({ type: ADD_FLASH_MESSAGE, message: {type: 'warning', text: errorMessage}});
    console.log(errorMessage);
  }
}

export function* watchGetLeague() {
  yield takeEvery(GET_LEAGUE, getLeague);
}

export function* watchGetLeagues() {
  yield takeEvery(GET_LEAGUES, getLeagues);
}

export function* watchGetPlayers() {
  yield takeEvery(GET_PLAYERS, getPlayers);
}

export function* watchGetTokens() {
  yield takeEvery(GET_AUTH_TOKEN, getTokens);
}

export function* watchGetUser() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}

export function* watchDropPlayer() {
  yield takeEvery(DROP_PLAYER, dropPlayer);
}

export function* watchAddPlayer() {
  yield takeEvery(ADD_PLAYER, addPlayer);
}

export function* watchSwapPlayers() {
  yield takeEvery(SWAP_PLAYERS, swapPlayers);
}

export function* watchCreateLeague() {
  yield takeEvery(CREATE_LEAGUE, createLeague);
}

export function* watchEditLeague() {
  yield takeEvery(EDIT_LEAGUE, editLeague);
}

export function* watchStartLeague() {
  yield takeEvery(START_DRAFT, startLeague);
}

export function* watchSubmitPickBid() {
  yield takeEvery(SUBMIT_PICK_BID, submitPickBid);
}

export function* watchDeleteLeague() {
  yield takeEvery(DELETE_LEAGUE, deleteLeague);
}

export function* watchLeagueJoin() {
  yield takeEvery(LEAGUE_JOIN, leagueJoin);
}

export function* watchLeagueLeave() {
  yield takeEvery(LEAGUE_LEAVE, leagueLeave);
}

export function* watchDeleteAccount() {
  yield takeEvery(DELETE_ACCOUNT, deleteAccount);
}

export function* watchUpdateAccount() {
  yield takeEvery(UPDATE_ACCOUNT, updateAccount);
}

export function* watchReversePick() {
  yield takeEvery(REVERSE_PICK, reversePick);
}

export function* watchRequestResetPassword() {
  yield takeEvery(REQUEST_RESET_PASSWORD, requestResetPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchSubmitExpertBid() {
  yield takeEvery(SUBMIT_EXPERT_BID, submitExpertBid);
}
