import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import  { RESET_PASSWORD } from '../../redux/actions/actionTypes';


const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (resetToken, password) => dispatch({type: RESET_PASSWORD, payload: {resetToken, password }})
  };
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    var queryParams = new URLSearchParams(this.props.location.search);

    this.state = {resetToken: queryParams.get("resetToken")}
  }

  changeResetToken(e) {
    this.setState({ resetToken: e.target.value });
  }

  changePassword(e) {
    this.setState({ password: e.target.value });
  }

  submitResetPassword(resetToken, password) {
    this.props.resetPassword(resetToken, password);
  }

  render() {
    const initialValues = {
      resetToken: this.state.resetToken,
      password: '',
      verifyPassword: ''
    };

    return (
      <div className="mx-auto w-64">
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.resetToken) {
                errors.resetToken = 'Required';
            }

            if (!values.password) {
                errors.password = 'Required';
            }

            if (values.password && !values.verifyPassword) {
                errors.verifyPassword = 'Required';
            }

            if (values.password && values.verifyPassword && values.verifyPassword !== values.password){
                errors.verifyPassword = 'Password Does Not Match';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.submitResetPassword(
              values.resetToken,
              values.password
            );
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="text-black pt-5">
              <h1 className="text-2xl text-white font-semibold mb-5">Password Reset</h1>
              <Field
                name="resetToken"
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                id="resetToken"
                type="hidden"
                placeholder="Password Reset Code"
              />
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="password"
                  component="div"
                />
                <Field
                  name="password"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="password"
                  type="password"
                  placeholder="New Password"
                />
              </div>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="verifyPassword"
                  component="div"
                />
                <Field
                  name="verifyPassword"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="verifyPassword"
                  type="password"
                  placeholder="Verify New Password"
                />
              </div>
              <div className="my-2 w-64">
                <button
                  disabled={isSubmitting}
                  className="w-full text-sm text-white bg-darker-green font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Reset Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));