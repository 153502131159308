import React from 'react';
import ReactFlashMessage from 'react-flash-message';

class FlashMessage extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.deleteFlashMessage(this.props.message.id);
  }
  render() {
    const { type, text } = this.props.message;
    return (
      <ReactFlashMessage duration={3000}>
        <div className="z-50 fixed w-full">
          <div
            className={`${type === 'success' ? 'bg-gray-100 border-gray-400 text-gray-700' : 'bg-red-100 border-red-400 text-red-700' } border px-2 py-1 absolute w-full flex`}
            role="alert"
          >
            <strong className="font-bold pr-12 flex-1">{text}</strong>
            <button onClick={this.onClick}>
              <span className="absolute top-0 bottom-0 right-0 px-2 py-2 flex-initial">
                <svg
                  className="fill-current h-5 w-5 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </ReactFlashMessage>
    );
  }
}

export default FlashMessage;
