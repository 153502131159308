import React from 'react';
import { connect } from 'react-redux';

import  {LEAGUE_JOIN } from '../../redux/actions/actionTypes';

const mapStateToProps = state => {
  return {
    loggedIn: state.user.user && state.user.user.id != null,
    ...state.leagueJoin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    joinLeague: (leagueCode) => dispatch({type: LEAGUE_JOIN, payload: {leagueCode}}),
  };
};

class LeagueJoin extends React.Component {
  componentDidMount() {
    if (this.props.loggedIn){
      this.props.joinLeague(this.props.match.params.leagueCode);
    }
  }

  render() {
    if (!this.props.loggedIn) {
      return (
        <div className="bg-red-100 border-t-4 border-red-400 rounded-b text-red-900 px-4 py-3" role="alert">
          <div className="flex">
            <div>
              <p className="font-bold">Oops! Unable to join a league.</p>
              <p className="text-sm">Make sure you have created an account are logged in.</p>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.loading) {
      return 'Loading';
    }

    if (this.props.success) {
      return(
        <div className="bg-teal-100 border-t-4 border-teal-400 rounded-b text-teal-900 px-4 py-3" role="alert">
          <div className="flex">
            <div>
              <p className="font-bold">Successfully joined the league!</p>
              <p className="text-sm">Check out your dashboard to view your new league.</p>
            </div>
          </div>
        </div>
      );
    }
    return(
      <div className="bg-red-100 border-t-4 border-red-400 rounded-b text-red-900 px-4 py-3" role="alert">
        <div className="flex">
          <div>
            <p className="font-bold">Oops! Unable to join league.</p>
            <p className="text-sm">{'Please confirm the code is correct, the league has not already begun, and that you haven\'t already joined.'}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueJoin);