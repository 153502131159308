import React from 'react';
import { connect } from 'react-redux';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import  { DELETE_ACCOUNT, UPDATE_ACCOUNT } from '../../redux/actions/actionTypes';

const mapStateToProps = state => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAccount: () => dispatch({type: DELETE_ACCOUNT}),
    updateAccount: (email, firstName, lastName, password) => dispatch({type: UPDATE_ACCOUNT, payload: {email, firstName, lastName, password}})
  };
};

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.submitAccountUpdate = this.submitAccountUpdate.bind(this);
    this.hideDeleteConfirm = this.hideDeleteConfirm.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.state = {
      showDeleteConfirm: false,
    };
  }

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  changeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  changeLastName(e) {
    this.setState({ lastName: e.target.value });
  }

  changePassword(e) {
    this.setState({ password: e.target.value });
  }

  submitAccountUpdate(email, firstName, lastName, password) {
    this.props.updateAccount(email, firstName, lastName, password || null);
  }

  deleteAccount() {
    this.props.deleteAccount();
    this.setState({showDeleteConfirm: false});
  }

  showDeleteConfirm() {
    this.setState({showDeleteConfirm: true});
  }

  hideDeleteConfirm() {
    this.setState({showDeleteConfirm: false});
  }

  render() {
    const initialValues = {
      email: this.props.user.email,
      firstName: this.props.user.first_name,
      lastName: this.props.user.last_name,
      password: '',
      verifyPassword: ''
    };

    return (
      <div className="mx-auto w-64">
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (!values.firstName) {
              errors.firstName = 'Required';
            }

            if (!values.lastName) {
              errors.lastName = 'Required';
            }

            if (values.password && !values.verifyPassword) {
              errors.verifyPassword = 'Required';
            }

            if (values.password && values.verifyPassword && values.verifyPassword !== values.password){
              errors.verifyPassword = 'Password Does Not Match';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.submitAccountUpdate(
              values.email,
              values.firstName,
              values.lastName,
              values.password
            );
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="text-black pt-5">
              <h1 className="text-2xl text-white font-semibold mb-5">ACCOUNT INFO</h1>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="email"
                  component="div"
                />
                <Field
                  name="email"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="email"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="firstName"
                  component="div"
                />
                <Field
                  name="firstName"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="lastName"
                  component="div"
                />
                <Field
                  name="lastName"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
              <h2 className="text-2xl text-white font-semibold mb-5">CHANGE PASSWORD</h2>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="password"
                  component="div"
                />
                <Field
                  name="password"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              <div className="mb-4">
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="verifyPassword"
                  component="div"
                />
                <Field
                  name="verifyPassword"
                  className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                  id="verifyPassword"
                  type="password"
                  placeholder="Verify Password"
                />
              </div>
              <div className="my-2 w-64">
                <button
                  disabled={isSubmitting}
                  className="w-full text-sm text-white bg-darker-green font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <button
          onClick={this.showDeleteConfirm}
          className="w-full text-sm text-white bg-red-700 font-bold py-1 px-2 mb-5 rounded focus:outline-none focus:shadow-outline"
        >
                  Delete Account
        </button>
        {this.state.showDeleteConfirm && <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none text-black">
                {/*header*/}
                <div className="relative px-6 pt-3 flex-auto">
                  <p>{'Are you sure you want delete your account?'}</p>
                  <p>{'This will also remove you from all leagues.'}</p>
                </div>
                <button
                  className="bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 m-5 mb-0 rounded"
                  onClick={this.deleteAccount}
                >
                  {'Yes, delete my account.'}
                </button>
                <button
                  className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 m-5 rounded"
                  onClick={this.hideDeleteConfirm}
                >
                  {'No, don\'t delete my account'}
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);