import { SET_LEAGUES } from '../actions/actionTypes';


const initialState = {
  leagues: []
};

const setLeagues = (state, action) => {
  action.payload.map(league => {
    return league.rosters.map(roster => {
      return roster.players.map(player => {
        return player.scores[0].points = player.scores[0].points + player.scores[0].bonus_points;
      });
    });
  });
  return {
    ...state,
    leagues: action.payload.sort((a, b) => parseInt(b.season) - parseInt(a.season))
  };
};

const leagues = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_LEAGUES) {
    return setLeagues(state, action);
  }

  // For now, don't handle any actions
  // and just return the state given to us.
  return state;
};

export default leagues;