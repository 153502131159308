import React, { Component } from 'react';

export default class PlayerCard extends Component {
  constructor(props){
    super(props);
    this.pickAllowed = this.pickAllowed.bind(this);
    this.inPickupWindow = this.inPickupWindow.bind(this);
    this.canEditRoster = this.canEditRoster.bind(this);
  }

  canEditRoster () {
    return (
      (this.props.currentUser && (this.props.currentUser.id === this.props.roster.user.id)) ||
      (this.props.currentUser && this.props.currentUser.is_staff)
    );
  }

  inPickupWindow() {
    const today = new Date();
    return this.props.league.roster_change_windows.find((w) => {
      const start = new Date(w.start);
      const end = new Date(w.end);
      return (w.window_type === 'pickup' && today >= start && today < end);
    });
  }

  pickAllowed() {
    const status = this.props.league.league_status;
    if (status === 'post_draft' && this.inPickupWindow()) {
      return true;
    }
    if (status === 'draft' && this.props.roster.is_picking && this.props.index === 0) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="w-full border-2 border-dashed rounded-full border-gray-200 flex p-1">
        <div className="w-16 h-16 justify-center items-center rounded-full bg-gray-100">
          <img
            className="object-cover rounded-full h-16 w-full"
            src="/images/default.jpg"
            alt="Select a Player"/>
        </div>
        <div className="flex-1 text-center mt-4">
          {this.pickAllowed() && this.canEditRoster() && <button
            onClick={() => this.props.topTenToggleClick(this.props.roster)}
            className="hover:bg-dark-green text-white bg-darker-green font-semibold hover:text-white text-sm py-1 px-2 rounded">
            Add Player
          </button>}
        </div>
      </div>
    );
  }
}
