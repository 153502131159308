import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LeagueNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ active: !this.state.active });
  }

  currentPicker() {
    return this.props.league.rosters.find((r) => r.is_picking);
  }

  currentBidder() {
    return this.props.league.rosters.find((r) => r.is_bidding);
  }

  windowLimits = (window) => {
    let limitString = '';
  
    if (this.props.league.atp_players) {
      limitString += `${window.atp_limit} ATP Players`;
    }
  
    if (this.props.league.wta_players) {
      if (limitString.length > 0) {
        limitString += ', ';
      }
      limitString += `${window.wta_limit} WTA Players`;
    }
  
    if (window.any_limit) {
      if (limitString.length > 0) {
        limitString += ', ';
      }
      limitString += `${window.any_limit} Any League`;
    }
  
    return limitString;
  }
  rosterWindows = (swapType) => {
    let currDate = new Date().toISOString().split("T")[0];
    let swapDates = [];
    if (this.props.league.roster_change_windows) {
      swapDates = this.props.league.roster_change_windows.filter(
        (type) => type.window_type === swapType
      );
      if (swapDates.length > 0 && swapType === "swap" ) {
        return swapDates.sort((a, b) => new Date(b.end) - new Date(a.end)).map((x, i) => {
          if (currDate >= x.start && currDate < x.end) {
            return <p key={i} className="text-sm bg-darker-green bg-opacity-25 p-1">{x.start} to {x.end} {this.windowLimits(x)}</p>;
          }
          return <p className="text-sm bg-opacity-25 p-1">{x.start} to {x.end} {this.windowLimits(x)}</p>;
        })
      }
      if (swapDates.length > 0 && swapType === "pickup") {
        return swapDates.sort((a, b) => new Date(b.end) - new Date(a.end)).map((x, i) => {
          if (currDate >= x.start && currDate < x.end) {
            return <p key={i} className="text-sm bg-darker-green bg-opacity-25 p-1">{x.start} to {x.end} {this.windowLimits(x)}</p>;
          }
          return <p className="text-sm bg-opacity-25 p-1">{x.start} to {x.end} {this.windowLimits(x)}</p>;
        })
      }
    }
  };

  render() {
    let chevron = "chevron-up";
    if (this.state.active === true){
      chevron = "chevron-up";
    }
    if (this.state.active === false) {
      chevron = "chevron-down";
    }
    return (
      <div className="flex items-center shadow mt-4 mb-4 sm:w-1/3 m-auto">
        <div className="w-full items-center text-sm bg-white">
          <div className="border-b w-full flex bg-darker-green text-white p-1">
            <div className="flex-1 text-left">
            <FontAwesomeIcon icon="exclamation-circle" className="ml-1 mr-1" />
            <h3 className="inline-block">League Info</h3>
            </div>
            <div className="flex-initial pr-1">
            <button onClick={this.toggleMenu}>
              <FontAwesomeIcon icon={chevron} />
            </button>
            </div>
          </div>

          {this.state.active && (
            <div>
              {this.props.league.league_status != null && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="football-ball" />
                  </div>

                  <div className="flex-item p-2">
                    {this.props.league.league_status === "pre_draft" &&
                      (!this.props.league.blind_bid || !this.props.pickBidsPending()) && (
                        <span className="">Pending Draft</span>
                      )}
                    {this.props.league.league_status === "pre_draft" &&
                      this.props.pickBidsPending() && (
                        <span className="">Pick Bids Pending</span>
                      )}
                    {this.props.league.league_status === "draft" && (
                      <span className="">Currently Drafting</span>
                    )}
                    {this.props.league.league_status === "post_draft" && (
                      <span className="">League Running</span>
                    )}
                    {this.props.league.league_status === "complete" && (
                      <span className="">League Complete</span>
                    )}
                  </div>
                </div>
              )}

              {this.props.league.league_status === "draft" && this.currentPicker() && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="directions" />
                  </div>

                  <div className="flex-item p-2">
                    <span className="">
                      {this.currentPicker().user.first_name}{" "}
                      {this.currentPicker().user.last_name} is picking
                    </span>
                  </div>
                </div>
              )}

              {this.props.league.league_status === "draft" && this.currentBidder() && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="directions" />
                  </div>

                  <div className="flex-item p-2">
                    <span className="">
                      {this.currentBidder().user.first_name}{" "}
                      {this.currentBidder().user.last_name} is bidding
                    </span>
                  </div>
                </div>
              )}

              {this.rosterWindows("swap") && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="exchange-alt" />
                  </div>
                  <div className="flex-item p-2">
                    <p className="text-left mb-2">Swap Windows</p>
                    <span className="text-left">{this.rosterWindows("swap")}</span>
                  </div>
                </div>
              )}

              {this.rosterWindows("pickup") && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="user-plus" />
                  </div>
                  <div className="flex-item p-2">
                    <p className="text-left mb-2">Pickup Windows</p>
                    <span className="text-left">{this.rosterWindows("pickup")}</span>
                  </div>
                </div>
              )}
              {this.props.league.league_notes !== "" && (
                <div className="border-b w-full flex">
                  {/* ICON */}
                  <div className="w-6 p-2 flex-item">
                    <FontAwesomeIcon icon="comment" />
                  </div>

                  <div className="flex-item p-2 text-left">
                    <span className="">
                      {this.props.league.league_notes}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LeagueNotifications;
