import React, { Component } from 'react';
import SignInForm from '../SignInForm/SignInForm';
import RegisterForm from '../RegisterForm/RegisterForm';
import RequestResetPasswordForm from '../RequestResetPasswordForm/RequestResetPasswordForm';
import logo from '../../assets/images/logo-3.png';

class Authentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRegister: false,
      showLogin: false,
      showResetPassword: false,
    };

    this.toggleClose = this.toggleClose.bind(this);
  }

  componentDidUpdate () {
    if (this.props.userShouldLogin) {
      this.setState({showLogin: true, showRegister: false});
    }
  }

  handleChange(type, e) {
    e.preventDefault();
    if (type === 'login') {
      this.setState({ showLogin: !this.state.showLogin, showRegister: false });
    }
    if (type === 'register') {
      this.setState({ showRegister: !this.state.showRegister, showLogin: false });
    }
    console.log(type)
    if (type === 'resetPassword') {
      this.setState({ showRegister: false, showLogin: false, showResetPassword: !this.state.showResetPassword});
    }
  }

  toggleClose(e){
    this.setState({ showLogin: false, showRegister: false, showResetPassword: false});
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex-grow mx-auto">
          <div className="h-full flex lg:flex-row flex-nowrap items-end pb-2">
            <button
              // disabled={isSubmitting}
              onClick={(e) => this.handleChange('login', e)}
              className="text-sm text-white font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Login
            </button>
            <button
              // disabled={isSubmitting}
              onClick={(e) => this.handleChange('register', e)}
              className="text-sm text-darker-green bg-white font-bold py-1 px-2 ml-1 border rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              SignUp
            </button>
          </div>
        </div>
        {this.state.showLogin ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none text-black">
                  {/*header*/}
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => this.toggleClose()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                  {/*body*/}
                  <img src={logo} className="mx-auto sm:inline px-2" style={{height: '60px', marginTop: '10px', marginBottom: '10px'}} alt="logo" />
                  <h1 className="text-center text-2xl text-black pb-6">Login</h1>

                  <div className="relative px-6 flex-auto">
                    <SignInForm setUserShouldLogin={this.props.setUserShouldLogin} getAuthToken={this.props.getAuthToken} />
                  </div>
                  <div className="text-center text-sm mx-auto px-6 pb-6 w-64">
                    <p>Dont have an account? <a href="/" onClick={(e) => this.handleChange('register', e)} className="text-dark-green" name="Sign Up">Sign Up</a> to start playing.</p>
                  </div>
                  <div className="text-center text-sm mx-auto px-6 pb-6 w-64">
                    <p>Forget your password? <a href="/" onClick={(e) => this.handleChange('resetPassword', e)} className="text-dark-green" name="Reset Password">Reset Password</a>.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {this.state.showRegister ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none text-black">
                  {/*header*/}
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => this.toggleClose()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                  {/*body*/}
                  <img src={logo} className="mx-auto sm:inline px-2" style={{height: '60px', marginTop: '10px', marginBottom: '10px'}} alt="logo" />
                  <h1 className="text-center text-2xl text-black pb-6">Sign Up</h1>

                  <div className="relative px-6 flex-auto">
                    <RegisterForm createUser={this.props.createUser} />
                  </div>
                  <div className="text-center text-sm mx-auto px-6 pb-6 w-64">
                    <p>Already have an account? <a href="/" onClick={(e) => this.handleChange('login', e)} className="text-dark-green" name="Sign In">Sign In</a> to start playing.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {this.state.showResetPassword ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none text-black">
                  {/*header*/}
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => this.toggleClose()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                  {/*body*/}
                  <img src={logo} className="mx-auto sm:inline px-2" style={{height: '60px', marginTop: '10px', marginBottom: '10px'}} alt="logo" />
                  <h1 className="text-center text-2xl text-black pb-6">Reset Password</h1>

                  <div className="relative px-6 flex-auto">
                    <RequestResetPasswordForm resetPassword={this.props.resetPassword} toggleClose={this.toggleClose} />
                  </div>
                  <div className="text-center text-sm mx-auto px-6 pb-6 w-64">
                    <p>Already have an account? <a href="/" onClick={(e) => this.handleChange('login', e)} className="text-dark-green" name="Sign In">Sign In</a> to start playing.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Authentication;
