import React, { Component } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { CSSTransition } from 'react-transition-group';

export default class PlayerCard extends Component {

  render() {
    const { name, country, id, scores } = this.props.player;
    // const player_rank = scores[0].rank;
    // const previous_rank = scores[0].previous_rank == null ? player_rank : scores[0].previous_rank;
    const scoreDelta = scores[0].points - scores[0].previous_points;
    const SelectedComponent = this.props.selectedComponent || '';
    const currentTournament = scores[0].current_tournament;
    const previousTournament = scores[0].previous_tournament;

    const showTopX = this.props.showTopX === true ? (this.props.league.top_x > this.props.index ? 'bg-green-600' : 'bg-green-700') : 'bg-green-700';

    const playerStyle = () => {
      if (this.props.player.league_org === 'ATP'){
        return this.props.showTopX === true ? (this.props.league.top_x > this.props.index ? 'bg-green-600 hover:bg-darker-green' : 'bg-green-700 hover:bg-darker-green') : 'bg-green-700 hover:bg-darker-green';
      }
      if (this.props.player.league_org === 'WTA'){
        return this.props.showTopX === true ? (this.props.league.top_x > this.props.index ? 'bg-purple-600 hover:bg-purple-800' : 'bg-purple-700 hover:bg-purple-800') : 'bg-purple-700 hover:bg-purple-800';
      }
    };
    

    let cleanedSrc = `/images/headshots/${name.replace(/\s/g, '-')}.png`
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const handleImageError = (e) => {
      e.target.src = '/images/default.jpg';
    };
    
    return (
      <React.Fragment key={id}>
        <button className="w-full btn_collapsible" onClick={() => this.props.selectPlayer(this.props.player.id)}>

          <div className={'cursor-pointer hover:bg-opacity-50 rounded-full bg-opacity-50 mb-2 ' + playerStyle()}>
            <div className="flex w-full items-center border-transparent">

              {/* Player Headshot */}
              <div className="w-full flex py-1 px-1" style={{minHeight: 72}}>

                <img className="inline object-cover w-16 h-16 mr-2 rounded-full bg-gray-100" onError={handleImageError} alt={name} src={cleanedSrc} />

                {/* Player Info */}
                <div className="pl-2 flex-grow text-left">
                  <p className="text-xs sm:text-sm text-white font-semibold">{name} <span className="pl-1 text-xl"><ReactCountryFlag countryCode={country} svg /> </span></p>
                  {this.props.activeSeason && <div>
                    <div className="text-xs truncate w-full normal-case font-normal text-gray-400">
                      {currentTournament ? (
                        <p data-tip="hello world" className="italic"><i className="fa fa-trophy text-gray-500 pr-1" aria-hidden="true"></i>{currentTournament}</p>
                      ) : (
                        <p className="italic"><i className="fa fa-trophy text-gray-500 pr-1" aria-hidden="true"></i>Current Tournament N/A</p>
                      )}
                      {previousTournament ? (
                        <p className="italic"><i className="fa fa-trophy text-gray-500 pr-1" aria-hidden="true"></i>{previousTournament}</p>
                      ) : (
                        <p className="italic"><i className="fa fa-trophy text-gray-500 pr-1" aria-hidden="true"></i>Prev. Tournament N/A</p>
                      )}
                    </div>
                  </div>}
                </div>
                <div className="md:w-full text-right flex-1">
                  <div className="px-6">
                    <p className="text-xl text-white font-semibold">{this.props.player.scores[0].points}<span className="text-xs">pts</span></p>
                    {this.props.activeSeason && <div className="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-400">
                      <span className="italic"><i className="text-grey-400">&#916;</i> PTS {scoreDelta}</span>
                    </div>}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </button>
        <CSSTransition
          in={this.props.selectedComponent && this.props.selected}
          timeout={0}
          classNames="list-transition"
          unmountOnExit
          appear
        >
          <div className="content transition duration-500 ease-in">
            <SelectedComponent {...this.props} />
          </div>
        </CSSTransition>
      </React.Fragment>

    );
  }
}

PlayerCard.defaultProps = {
  selectPlayer: () => {},
};
