import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import { GET_PLAYERS, ADD_PLAYER, SWAP_PLAYERS } from '../../redux/actions/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerCard from '../Player/PlayerCard';


class PlayerSwapList extends Component {
  render() {
    var Players = this.props.roster.players.map((player, i) => {
      return (
        <div key={i}
          onClick={() => this.props.swapPlayers(player, this.props.newPlayer, this.props.roster.id, this.props.league.id)}
          className="flex"
        >
          <div className="flex-shrink pr-5">
            <button
              className="mt-6 inline hover:bg-dark-green bg-darker-green text-xs font-semibold text-white py-1 px-2 rounded"
            >
              <FontAwesomeIcon icon="exchange-alt" />
            </button>
          </div>
          <div className="flex-grow">
            <PlayerCard
              key={i}
              roster={this.props.roster}
              league={this.props.league}
              player={player}
              index={i}
              currentUser={this.props.currentUser}
              selectPlayer={() => {}}
              selected={false}
              addPlayer={() => {}}
              selectedComponent={PlayerSelectorActions}
            />
          </div>
        </div>
      );
    });
    return (
      <div className="p-5 border-2 border-white border-opacity-50 text-2xl text-white rounded-md mb-10">
        <h2 className='text-white mb-2'>Choose a player to swap with</h2>
        {Players}
      </div>
    );
  }
}

class PlayerSelectorActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swapListActive: false
    };
    this.toggleSwapList = this.toggleSwapList.bind(this);
  }

  toggleSwapList() {
    this.setState({swapListActive: !this.state.swapListActive});
  }

  canPick() {
    var currentPickingRoster = this.props.league.rosters.find(r => r.is_picking);
    return (currentPickingRoster && this.props.currentUser.id === currentPickingRoster.user.id) ||
           this.props.currentUser.is_staff ||
           this.props.league.league_status === 'post_draft';
  }

  canSwap() {
    return this.props.league.league_status === 'post_draft';
  }

  render() {
    var rosterHasPlayers = this.props.roster && this.props.roster.players.length > 0;

    return(
      <React.Fragment>
        <div className="m-4 text-center">
          {this.canPick() ? (
            <button onClick={() => this.props.addPlayer(this.props.roster, this.props.league, this.props.player)} className="hover:bg-dark-green bg-darker-green text-xs font-semibold text-white py-1 px-2 rounded"><FontAwesomeIcon icon="plus" /> Add</button>
          ) : (
            <p className='text-sm text-gray-600 pb-4'>It is not currently your pick.</p>
          )}
          {rosterHasPlayers && this.props.league.league_status === 'post_draft' && (
            <button onClick={this.toggleSwapList} className="hover:bg-dark-green bg-darker-green text-xs font-semibold text-white py-1 px-2 rounded"><FontAwesomeIcon icon="exchange-alt" /> Swap</button>
          )}
        </div>
        {this.state.swapListActive && <div>
          <PlayerSwapList newPlayer={this.props.player} swapPlayers={this.props.swapPlayers} roster={this.props.roster} league={this.props.league} />
        </div>}
      </React.Fragment>
    );
  }
}

class PlayerSelectorList extends Component{

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      selectedPlayer: null,
      season: '2022',
    };
    this.changeSearch = this.changeSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.search = this.search.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.selectPlayer = this.selectPlayer.bind(this);
    this.changeSeason = this.changeSeason.bind(this);
  }

  selectPlayer(playerId){
    this.setState({selectedPlayer: playerId});

    if (playerId === this.state.selectedPlayer){
      this.setState({selectedPlayer: null});
    }
  }

  changeSearch(e) {
    e.preventDefault();
    this.setState({ searchTerm: e.target.value });
  }

  submitSearch(e) {
    e.preventDefault();
    this.search();
  }

  changeSeason(season) {
    this.setState({ season: season }, () => {
      this.search();
    });
  }

  search() {
    if (this.state.searchTerm.length === 0) {
      this.props.getPlayers({
        league_org: this.props.league_org,
        season: this.state.season,
        exclude_league_picks: this.props.league.id,
      });
    } else {
      this.props.getPlayers({
        league_org: this.props.league_org,
        search: this.state.searchTerm,
        season: this.state.season,
        exclude_league_picks: this.props.league.id,
      });
    }
  }

  loadMore(e) {
    e.preventDefault();
    this.props.getPlayers({
      league_org: this.props.league_org,
      offset: this.props.players.length,
      season: this.state.season,
      exclude_league_picks: this.props.league.id,
    });
  }

  render(){
    let players = this.props.players.sort((p1, p2) => {
      return p2.scores[0].points - p1.scores[0].points;
    }).map((player, i) => {

      if (this.props.currentUser) {
        return <PlayerCard
          key={i}
          roster={this.props.roster}
          league={this.props.league}
          player={player}
          index={i}
          currentUser={this.props.currentUser}
          selectPlayer={this.selectPlayer}
          selected={this.state.selectedPlayer === player.id}
          addPlayer={this.props.addPlayer}
          swapPlayers={this.props.swapPlayers}
          selectedComponent={PlayerSelectorActions}
          showTopX={false}
        />;
      } else {
        return <PlayerCard
          key={i}
          roster={this.props.roster}
          league={this.props.league}
          player={player}
          index={i}
          currentUser={this.props.currentUser}
          selected={this.state.selectedPlayer === player.id}
          addPlayer={this.props.addPlayer}
          swapPlayers={this.props.swapPlayers}
          selectedComponent={PlayerSelectorActions}
          showTopX={false}
        />;
      }
    });
    let currentYear = new Date().getFullYear();
    let currentSeason = currentYear.toString();
    let pastSeason = (currentYear - 1).toString();

    return(
      <div className="w-full sm:w-2/4 sm:px-4 pt-8">
        <div className="w-full bg-darker-green bg-opacity-75 p-4 border-b border-green-100 mb-2">
          <div className="text-gray-700 text-center">
            <h1 className="text-white font-bold text-3xl">
              {this.props.league_org === 'ATP' ? (
                <span className="text-black">{this.props.league_org}</span>
              ) : (
                <span className="text-purple-700">{this.props.league_org}</span>
              )}{' '}
              PLAYERS
            </h1>
            <div className="w-2/3 m-auto">
              <button
                onClick={() => this.changeSeason(currentSeason)}
                className={`w-1/2 hover:bg-dark-green text-white text-sm border-2 text-white py-1 rounded ${
                  this.state.season === currentSeason
                    ? 'bg-dark-green underline font-bold'
                    : 'bg-darker-green'
                }`}
              >
                {currentSeason}
              </button>
              <button
                onClick={() => this.changeSeason(pastSeason)}
                className={`w-1/2 hover:bg-dark-green text-white text-sm border-2 text-white py-1 px-2 rounded ${
                  this.state.season === pastSeason
                    ? 'bg-dark-green underline font-bold'
                    : 'bg-darker-green'
                }`}
              >
                {pastSeason}
              </button>
            </div>
            <form onSubmit={this.submitSearch}>
              <div className="flex items-center justify-center my-6">
                <div className="border text-white border-white rounded-full rounded overflow-hidden flex">
                  <input
                    type="text"
                    className="px-4 py-2 bg-darker-green bg-opacity-50 focus:outline-none"
                    placeholder="Search..."
                    onChange={this.changeSearch}
                    value={this.state.searchTerm}
                  />
                  <button className="flex items-center justify-center px-4 bg-darker-green opacity-50" type="submit">
                    <svg
                      className="h-4 w-4 text-white"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {players.length > 0 ? players : <div className="rounded-full bg-white text-center"><h3>No Players Found</h3></div>}
        {players.length >= 20 && !this.state.searchTerm && (
          <button
            onClick={this.loadMore}
            className="w-full bg-darker-green hover:bg-dark-green text-white text-sm border-2 text-white py-1 px-2 rounded"
          >
            Load More
          </button>
        )}
      </div>

    );
  }
}

class PlayerSelector extends Component{
  render() {
    var AtpPlayers, WtaPlayers, roster;

    if (this.props.league.league_status === 'draft' || !this.props.currentUser) {
      roster = this.props.league.rosters.find(r => r.is_picking);
    } else {
      roster = this.props.pickingRoster || this.props.league.rosters.find(r => r.user.id === this.props.currentUser.id);
    }

    if (this.props.league.atp_players) {
      AtpPlayers = <PlayerSelectorList
        addPlayer={this.props.addPlayer}
        getPlayers={this.props.getPlayers}
        swapPlayers={this.props.swapPlayers}
        league={this.props.league}
        league_org="ATP"
        roster={roster}
        players={this.props.atp_players}
        currentUser={this.props.currentUser}
      />;
    }

    if (this.props.league.wta_players) {
      WtaPlayers = <PlayerSelectorList
        addPlayer={this.props.addPlayer}
        getPlayers={this.props.getPlayers}
        swapPlayers={this.props.swapPlayers}
        league={this.props.league}
        league_org="WTA"
        roster={roster}
        players={this.props.wta_players}
        currentUser={this.props.currentUser}
      />;
    }

    return (
      <React.Fragment>
        {AtpPlayers}
        {WtaPlayers}
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return{
    players: state.players.players,
    atp_players: state.players.atp_players,
    wta_players: state.players.wta_players,
    league: state.league,
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPlayers: (filters) => dispatch({type: GET_PLAYERS, filters}),
    addPlayer: (roster, league, player) => dispatch({type: ADD_PLAYER, payload: {roster, league, player}}),
    swapPlayers: (currentPlayer, newPlayer, rosterId, leagueId) => dispatch({type: SWAP_PLAYERS, payload: {currentPlayer, newPlayer, rosterId, leagueId}})
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PlayerSelector);