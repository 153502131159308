import { SET_ROSTERS } from '../actions/actionTypes';


const initialState = {
  roster: {
    id: null,
    players: [],
    league_id: null,
    user_id: null
  },
  rosters: []
};

const setRosters = (state, action) => {
  return {...state, rosters: action.payload};
};

const roster = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_ROSTERS) {
    return setRosters(state, action);
  }

  return state;
};

export default roster;