import React, { Component } from 'react';
import logo from '../../assets/images/logo-3.png';
import Authentication from '../Authentication/Authentication';
// import LeagueMenu from '../LeagueMenu/LeagueMenu';
import { Link } from 'react-router-dom';

class Header extends Component{
  render () {
    return (
      <nav className="w-full text-green-100 bg-gradient-to-b from-darker-green to-mid-green body-font shadow">
        <div className="container max-w-screen-lg mx-auto px-6 py-2 flex sm:flex-row flex-col justify-between">
          <div className="">
            <div className="sm:float-left ">
              <a href="/" alt ="Home" title="Home">
                <img src={logo} className="mx-auto sm:inline px-4" style={{height: '60px', marginTop: '10px'}} alt="logo" />
              </a>
            </div>
            <div className="sm:float-left mt-6 text-center sm:text-left">
              <a href='/' title="Fantasy Tennis" alt="Fantasy Tennis">
                <h1 className="uppercase leading-none -mb-2 font-semibold">Fantasy Tennis</h1>
                <span className="italic text-light-green text-xs leading-none">Beta</span>
              </a>
            </div>
          </div>
          {/*<div className="justify-center items-center flex mt-5">{this.props.leagues && this.props.leagues.length > 0 && <LeagueMenu leagues={this.props.leagues} />}</div>*/}
          <div className="flex flex-col">
            {this.props.user && <div className="flex flex-row-reverse mt-2 m-auto sm:mr-1">
              <a className="text-sm text-white font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline" href="/" onClick={this.props.logoutUser}>Log Out</a>
              <Link className="text-sm text-white font-bold py-1 px-2 mr-2 border rounded focus:outline-none focus:shadow-outline" to="/user/profile">Account</Link>
              <a className="mr-2 text-sm text-white font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline" href="/">My Leagues</a>
              {/* {(this.props.user && this.props.user.is_staff) && <a className="mr-2 text-sm text-white font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline" href={process.env.REACT_APP_ADMIN_URL}>Admin</a>} */}
            </div>}
            {this.props.user && this.props.user.first_name &&
              <p className="pt-2 text-center sm:text-right text-sm italic">Welcome Back, {this.props.user.first_name} {this.props.user.last_name}</p>
            }
            {!this.props.user && <div className="mt-5 mx-auto"><Authentication resetPassword={this.props.resetPassword} setUserShouldLogin={this.props.setUserShouldLogin} userShouldLogin={this.props.userShouldLogin} getAuthToken={this.props.getAuthToken} createUser={this.props.createUser} /></div>}
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;