import { SET_LEAGUE } from '../actions/actionTypes';


const initialState = {
  id: null,
  name: '',
  rosters: [],
  start: '',
  end: ''
};

const setLeague = (state, action) => {
  var highestBid = 0;

  action.payload.rosters.map(roster => {
    if (!roster.pick_index){
      highestBid = Math.max(roster.draft_round_bid, highestBid);
    }
    return roster.players.map(player => {
      return player.scores[0].points = player.scores[0].points + player.scores[0].bonus_points;
    });
  });

  return {...state, ...action.payload, highestBid};
};

const league = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_LEAGUE) {
    return setLeague(state, action);
  }

  return state;
};

export default league;