import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.submitLogin = this.submitLogin.bind(this);
  }

  componentDidMount(){
    this.props.setUserShouldLogin(false);
  }

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  changePassword(e) {
    this.setState({ password: e.target.value });
  }

  submitLogin(email, password) {
    this.props.getAuthToken(email, password);
  }

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.submitLogin(values.email, values.password);
          setSubmitting(false); // TODO - call this in the saga?
        }}
      >
        {({ isSubmitting }) => (
          <Form className="pb-2 text-black">
            <div className="mb-4">
              <div className="flex justify-between">
                <label htmlFor="email">Email</label>
                <ErrorMessage
                  className="ml-2 text-red-500 text-xs italic"
                  name="email"
                  component="div"
                />
              </div>
              <Field
                name="email"
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                id="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <div className="">
              <div className="flex justify-between">
                <label htmlFor="password">Password</label>
                <ErrorMessage
                  className="text-red-500 text-xs italic"
                  name="password"
                  component="div"
                />
              </div>
              <Field
                name="password"
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                id="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="text-right">
              {/*
              <a
                href="#"
                className="text-sm text-dark-green leading-normal hover:underline mb-6"
              >
                Forget Password?
              </a>
              */}
            </div>
            <div className="my-4">
              <button
                disabled={isSubmitting}
                className="w-full text-sm text-white bg-darker-green font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SignInForm;
