import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

class RequestResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.submitPasswordReset = this.submitPasswordReset.bind(this);
  }

  changeEmail(e) {
    this.setState({ email: e.target.value });
  }

  submitPasswordReset(email) {
    this.props.resetPassword(email)
  }

  render() {
    return (
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.submitPasswordReset(values.email);
          setSubmitting(false); // TODO - call this in the saga?
          this.props.toggleClose();
        }}
      >
        {({ isSubmitting }) => (
          <Form className="pb-2 text-black">
            <div className="mb-4">
              <div className="flex justify-between">
                <label htmlFor="email">Email</label>
                <ErrorMessage
                  className="ml-2 text-red-500 text-xs italic"
                  name="email"
                  component="div"
                />
              </div>
              <Field
                name="email"
                className="shadow appearance-none border rounded w-64 py-2 px-3 text-grey-darker"
                id="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <div className="my-4">
              <button
                disabled={isSubmitting}
                className="w-full text-sm text-white bg-darker-green font-bold py-1 px-2 border rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Request Password Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default RequestResetPasswordForm;
