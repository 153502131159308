import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

export default class PickBidForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      editingBid: false
    };

    this.setEditingBid = this.setEditingBid.bind(this);
  }

  setEditingBid(option) {
    this.setState({editingBid: option});
  }

  pickHeading() {
    return this.props.league.player_selection === 'blind_bid_ebay' ? 'Maxiumum bid' : 'Pick Bid';
  }

  render() {
    const pickBidPending = this.props.league.blind_bid && this.props.roster.pick_bid == null;
    const canSetBid = (this.props.currentUser && this.props.currentUser.is_staff) || (this.props.currentUser && this.props.roster.user.id === this.props.currentUser.id);

    if (canSetBid && (pickBidPending || this.state.editingBid)) {
      return (
        <div>
          <Formik
            initialValues={{pick_bid: this.props.roster.pick_bid}}
            validate={values => {
              const errors = {};
              if (values.pick_bid < 0) {
                errors.pick_bid = 'Bid must be zero or greater.';
              }
              if (values.pick_bid == null || values.pick_bid === '') {
                errors.pick_bid = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              this.props.submitPickBid(
                this.props.league.id,
                this.props.roster.id,
                values.pick_bid
              );
              this.setEditingBid(false);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
                {this.props.league.player_selection === 'blind_bid_ebay' && <>
                  <p className="pt-2 text-sm italic">
                    Ebay Bid - League members all submit a secret bid with the highest bidder winning the 1st overall draft pick. Bids will be automatically adjusted down to 1 point more than the next highest bid. Your final bid amount will be deducted from total team score
                  </p>
                  <p className="pt-2 text-sm italic mb-5">
                    Example:
                    <ul className="list-disc list-inside my-2">
                      <li>Team A bids 3000 points</li>
                      <li>Team B bids 2000 points</li>
                      <li>Team C bids 1000 points</li>
                    </ul>
                    Team A will draft 1st and will start the league with -2001 points, Team B will be assigned -1001 points and will draft 2nd. Team C will be assigned -1 point and will draft 3rd.
                  </p>
                </>}
                {this.props.league.player_selection === 'blind_bid' && <>
                  <p className="pt-2 text-sm italic">
                    Blind Bid - League members all submit a secret bid with the highest bidder winning the 1st overall draft pick. The bid amount will be deducted from total team score.
                  </p>
                  <p className="pt-2 text-sm italic mb-5">
                    Example:
                    <ul className="list-disc list-inside my-2">
                      <li>Team A bids 3000 points</li>
                      <li>Team B bids 2000 points</li>
                      <li>Team C bids 1000 points</li>
                    </ul>
                    Team A will draft 1st and will start the league with -3000 points, and so on…
                  </p>
                </>}
                <h1 className="text-2xl text-center text-gray-900 mb-5">{this.pickHeading()}</h1>
                <div className="mb-6">
                  <Field name="pick_bid" className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline" id="pick_bid" type="number" placeholder="# Points to bid" />
                  <ErrorMessage className="text-red-500 text-xs italic" name='pick_bid' component="div" />
                </div>
                <div className="flex items-center justify-center">
                  <button disabled={isSubmitting} className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Submit bid
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else {
      return (
        <div className="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
          {pickBidPending &&
            <h2 className="text-center text-yellow-700">Pick Bid Pending</h2>

          }
          {!pickBidPending &&
          <div className="text-center">
            <h2 className="text-center text-green-900">Pick Order Bid Submitted
            </h2>
            {canSetBid && (<small><button onClick={() => this.setEditingBid(true)}><i className="fa fa-edit fill-current"></i> Change bid</button></small>)}
          </div>
          }
        </div>
      );

    }
  }
}
