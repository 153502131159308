import { SET_ATP_PLAYERS, SET_WTA_PLAYERS, SET_PLAYERS, APPEND_ATP_PLAYERS, APPEND_WTA_PLAYERS, APPEND_PLAYERS } from '../actions/actionTypes';

const initialState = {
  atp_players: [],
  wta_players: [],
  players: []
};

function calculate_points(players) {
  players.map(player => {
    return player.scores[0].points = player.scores[0].points + player.scores[0].bonus_points;
  });
}
const setAtpPlayers = (state, action) => {
  calculate_points(action.payload);
  return {...state, atp_players: action.payload};
};

const setWtaPlayers = (state, action) => {
  calculate_points(action.payload);
  return {...state, wta_players: action.payload};
};

const setPlayers = (state, action) => {
  calculate_points(action.payload);
  return {...state, players: action.payload};
};

const appendPlayers = (state, action) => {
  calculate_points(action.payload);
  const newPlayers = [...state.players, ...action.payload];
  return {...state, players: newPlayers};
};

const appendAtpPlayers = (state, action) => {
  calculate_points(action.payload);
  const newPlayers = [...state.atp_players, ...action.payload];
  return {...state, atp_players: newPlayers};
};

const appendWtaPlayers = (state, action) => {
  calculate_points(action.payload);
  const newPlayers = [...state.wta_players, ...action.payload];
  return {...state, wta_players: newPlayers};
};


const players = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_ATP_PLAYERS) {
    return setAtpPlayers(state, action);
  }

  if (action.type === SET_WTA_PLAYERS) {
    return setWtaPlayers(state, action);
  }

  if (action.type === SET_PLAYERS) {
    return setPlayers(state, action);
  }

  if (action.type === APPEND_PLAYERS) {
    return appendPlayers(state, action);
  }

  if (action.type === APPEND_ATP_PLAYERS) {
    return appendAtpPlayers(state, action);
  }

  if (action.type === APPEND_WTA_PLAYERS) {
    return appendWtaPlayers(state, action);
  }
  // For now, don't handle any actions
  // and just return the state given to us.
  return state;
};

export default players;