import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './ScoreCard.css';
import PlayerCard from '../Player/PlayerCard';
import EmptyPlayerCard from '../Player/EmptyPlayerCard';
import PickBidForm from '../PickBidForm';
import ExpertBidForm from '../ExpertBidForm'

class ScoreCard extends Component{
  constructor(props){
    super(props);
    this.state = {selectedPlayer: null};

    this.selectPlayer = this.selectPlayer.bind(this);
  }

  selectPlayer(playerId){
    this.setState({selectedPlayer: playerId});

    if (playerId === this.state.selectedPlayer){
      this.setState({selectedPlayer: null});
    }
  }

  canEditRoster () {
    return (
      (this.props.currentUser && (this.props.currentUser.id === this.props.roster.user.id)) ||
      (this.props.currentUser && this.props.currentUser.is_staff)
    );
  }

  render(){
    // Limit Amount of Players able to score points.
    let topx = this.props.league.top_x;
    let sorted_players = this.props.roster.players.sort((p1, p2) => {
      return p2.scores[0].points - p1.scores[0].points;
    });

    // Total Points for a User
    let points = sorted_players.map((scores, i) => {
      if(topx){
        if (i < topx){
          return scores.scores[0].points;
        }
        return 0;
      }
      return scores.scores[0].points;
    });
    var sum = 0;

    if (points && points.length > 0) {
      sum = points.reduce((total, amount) => total + amount);
    }

    if (this.props.league.blind_bid && this.props.league.league_status !== 'pre_draft' && this.props.rankWithBids) {
      sum = sum - this.props.roster.pick_bid;
    }

    if (this.props.league.draft_form == 'expert' && this.props.roster.pick_index) {
      if (this.props.roster.draft_round_bid && this.props.rankWithBids) {
        sum = sum - this.props.roster.draft_round_bid;
      }
    }

    let topXHeader = () => {
      if (this.props.league.top_x) {
        return (
          <span className="italic"> Top {topx}</span>
        );
      } else {
        return;
      }
    };

    let trophy = () => {
      if (this.props.index === 0) {
        return (
          <div className="flex-initial"><i className="fa fa-trophy pt-1 pr-4 fa-2x text-yellow-500" aria-hidden="true"></i></div>
        );
      }
      if (this.props.index === 1) {
        return (
          <div className="flex-initial"><i className="fa fa-trophy fa-2x pt-1 pr-4 text-gray-500" aria-hidden="true"></i></div>
        );
      }
      if (this.props.index === 2) {
        return (
          <div className="flex-initial"><i className="fa fa-trophy fa-2x pt-1 pr-4 text-orange-600" aria-hidden="true"></i></div>
        );
      } else {
        return (
          <div className="flex-initial"><h1 className="text-3xl pr-2 text-white">{this.props.index+1}. </h1></div>
        );
      }
    };

    let headers = () => {
      return (
        <React.Fragment>
          <div className="flex items-stretch bg-darker-green bg-opacity-75 mb-2 p-4">
            <div className="flex-1 text-gray-700 text-center">
              <div className="flex">
                {trophy()}
                <div className="flex-initial text-left">
                  <h2 className="font-small font-semibold text-white">{this.props.user.first_name} {this.props.user.last_name}</h2>

                  <div className="text-gray-100">
                    {this.props.roster.pick_index != null && (!this.props.league.blind_bid || this.props.league.league_status !== 'pre_draft') && !(this.props.league.draft_form == 'expert' && this.props.league.league_status === 'pre_draft') &&
                      <span className="text-xs mr-2"><span className="font-semibold">Pick #</span>{this.props.roster.pick_index}</span>
                    }
                    {this.props.roster.is_picking &&
                      <span className="text-xs">Currently Picking</span>
                    }
                    {this.props.roster.is_bidding &&
                      <span className="text-xs">Currently Bidding</span>
                    }
                    {this.props.roster.buy_in != null &&
                        <p className="text-xs -mt-1"><span className="font-semibold">Buy In: </span>${this.props.roster.buy_in}</p>
                    }
                    {this.props.league.blind_bid && this.props.league.league_status !== 'pre_draft' &&
                      <p className="text-xs -mt-1"><span className="font-semibold">Pick Bid: </span>{this.props.roster.pick_bid} points</p>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 text-gray-200 text-right">
              <div className="text-xs truncate w-full normal-case font-normal text-gray-200">{topXHeader()}</div><h3 className="text-2xl -mt-2 text-white">{sum}</h3>
            </div>

            {/* <div className="flex-1 text-gray-700 text-right hidden md:block">
              {this.props.roster.pick_index != null &&
                  <p className="text-sm"><span className="font-medium">Picked:</span> {this.props.roster.pick_index}</p>
              }
              {this.props.roster.buy_in != null &&
                  <p className="text-sm"><span className="font-medium">Buy In: $</span>{this.props.roster.buy_in}</p>
              }
            </div> */}
          </div>

          {/* <div className="flex bg-gray-800">
            <div className="flex-1 text-white text-center px-2 m-2 text-xl">
              <h3 >Player</h3>
            </div>
            <div className="flex-1 text-white text-center px-2 m-2 text-xl">
              <h3>Points</h3>
            </div>
            <div className="flex-1 text-white text-center px-2 m-2 text-xl hidden md:block">
              <h3>Tournament</h3>
            </div>
          </div> */}
        </React.Fragment>
      );
    };

    let maxRosterSize = this.props.league.initial_roster_size;
    if (this.props.league.roster_change_windows.length > 0) {
      maxRosterSize = this.props.league.roster_change_windows.filter(w => w.window_type === 'pickup').reduce((total, w) => {
        return total + w.atp_limit + w.wta_limit + w.any_limit;
      }, this.props.league.initial_roster_size);
    }

    let emptyPlayerLength;
    if (this.props.league.league_status === 'pre_draft') {
      emptyPlayerLength = this.props.league.initial_roster_size;
    } else if (this.props.league.league_status === 'draft') {
      emptyPlayerLength = this.props.league.initial_roster_size - this.props.roster.players.length;
    } else {
      emptyPlayerLength = maxRosterSize - this.props.roster.players.length;
    }

    let emptyPlayers;

    if (emptyPlayerLength > 0){
      emptyPlayers = Array.from(Array(emptyPlayerLength)).map((x, index) => {
        return <EmptyPlayerCard
          key={index}
          index={index}
          topTenToggleClick={this.props.topTenToggleClick}
          league={this.props.league}
          roster={this.props.roster}
          currentUser={this.props.currentUser}
        />;
      });
    }

    let players = sorted_players.map((player, i) => {
      return <PlayerCard
        key={i}
        roster={this.props.roster}
        league={this.props.league}
        player={player}
        index={i}
        currentUser={this.props.currentUser}
        selectPlayer={this.selectPlayer}
        selected={this.state.selectedPlayer === player.id}
        activeSeason={true}
        showTopX={true}
      />;
    });

    if (this.props.league.blind_bid && this.props.league.league_status === 'pre_draft') {
      return (
        <div className="pt-8 w-full sm:w-2/4 sm:px-4">
          {headers()}
          <PickBidForm
            league={this.props.league}
            roster={this.props.roster}
            currentUser={this.props.currentUser}
            submitPickBid={this.props.submitPickBid}
            pickBidsPending={this.props.pickBidsPending}
          />
        </div>
      );
    }

    if (this.props.league.draft_form == 'expert' && this.props.league.league_status === 'draft' && this.props.roster.is_bidding && this.canEditRoster()) {
      return (
        <div className="pt-8 w-full sm:w-2/4 sm:px-4">
        {headers()}
        <ExpertBidForm 
          currentUser={this.props.currentUser} 
          roster={this.props.roster} 
          league={this.props.league} 
          currentBid={this.props.league.highestBid} 
          submitExpertBid={this.props.submitExpertBid} 
        />
      </div>
      )
    }
    return(
      <div className="pt-8 w-full sm:w-2/4 sm:px-4">
        {headers()}
        {players}
        {emptyPlayers}
      </div>
    );
  }
}

export default ScoreCard;