import React from 'react';
import { connect } from 'react-redux';
import {Link } from 'react-router-dom';
import LeageCreateForm from '../components/LeagueCreateForm/LeageCreateForm';
import { GET_LEAGUES, CREATE_LEAGUE, EDIT_LEAGUE, DELETE_LEAGUE, LEAGUE_LEAVE } from '../redux/actions/actionTypes';


const mapStateToProps = state => {
  return {
    leagues: state.leagues.leagues,
    currentUser: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLeagues: () => dispatch({type: GET_LEAGUES}),
    createLeague: (data) => dispatch({type: CREATE_LEAGUE, payload: data}),
    editLeague: (id, data) => dispatch({type: EDIT_LEAGUE, leagueId: id, payload: data}),
    deleteLeague: (id) => dispatch({type: DELETE_LEAGUE, leagueId: id}),
    leagueLeave: (id) => dispatch({type: LEAGUE_LEAVE, leagueId: id})
  };
};

class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show: false,
      leagueBtnText: 'New League',
      leagueEditForm: [],
      showLeaveLeague: false,
      leaveLeagueId: null,
    };
    this.showModal = this.showModal.bind(this);
    this.confirmLeaveLeague = this.confirmLeaveLeague.bind(this);
    this.leaveLeague = this.leaveLeague.bind(this);
    this.declineLeaveLeague = this.declineLeaveLeague.bind(this);
  }

  componentDidMount() {
    this.props.getLeagues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.leagues.length !== prevProps.leagues.length && this.state.show) {
      this.showModal();
    }
  }

  showModal(leagueInfo) {
    let leagueBtn = this.state.leagueBtnText;
    if (leagueBtn === 'New League'){
      leagueBtn = 'Close';
    } else {
      leagueBtn = 'New League';
    }

    this.setState({
      show: !this.state.show,
      leagueBtnText: leagueBtn,
      leagueEditForm: leagueInfo
    });
  }

  trophy(index) {
    if (index === 1) {
      return (
        <span className="fa-stack text-xl">
          <i className="fa fa-trophy fa-stack-2x text-yellow-500"></i>
          <span className="fa fa-stack-1x">
            <span className="block text-gray-900 -mt-2 font-semibold text-sm font-sans">
              <p>{index}</p>
            </span>
          </span>
        </span>
      );
    }
    if (index === 2) {
      return (
        <span className="fa-stack text-xl">
          <i className="fa fa-trophy fa-stack-2x text-gray-500"></i>
          <span className="fa fa-stack-1x">
            <span className="block text-gray-900 -mt-2 font-semibold text-sm font-sans">
              <p>{index}</p>
            </span>
          </span>
        </span>
      );
    }
    if (index === 3) {
      return (
        <span className="fa-stack text-xl">
          <i className="fa fa-trophy fa-stack-2x text-orange-600"></i>
          <span className="fa fa-stack-1x">
            <span className="block text-gray-900 -mt-2 font-semibold text-sm font-sans">
              <p>{index}</p>
            </span>
          </span>
        </span>
      );
    } else {
      return (
        <h4 className="text-2xl font-semibold">{index}<span className="align-text-top text-xs">th</span></h4>
      );
    }
  }

  hasPendingPickBids(league) {
    return league.blind_bid && league.rosters.findIndex(r => r.pick_bid == null) !== -1;
  }

  getRosterData(league) {
    let topx = league.top_x;
    var rosters = league.rosters.sort((rosterA, rosterB) => {
      let playersA = rosterA.players;
      let playersB = rosterB.players;

      if (topx) {
        playersA = playersA.slice(0, topx);
        playersB = playersB.slice(0, topx);
      }
      var aTotal = playersA.reduce(
        (total, p) => total + p.scores[0].points,
        0
      );
      var bTotal = playersB.reduce(
        (total, p) => total + p.scores[0].points,
        0
      );

      if (league.blind_bid && league.league_status !== 'pre_draft') {
        aTotal = aTotal - rosterA.pick_bid;
        bTotal = bTotal - rosterB.pick_bid;
      }

      // For tie breakers we give the win to roster with the highest top pick.
      if (bTotal === aTotal && rosterA.players.length > 0 && rosterB.players.length > 0) {
        aTotal += rosterA.players[0].scores[0].points;
        bTotal += rosterB.players[0].scores[0].points;
      }
      return bTotal - aTotal;
    });

    var userRosterIndex = rosters.findIndex(r => r.user.id === this.props.currentUser.id);
    var displayRosterIndex = 0;

    // If user doesn't have a roster for the league then use the top player
    if (userRosterIndex !== -1) {
      displayRosterIndex = userRosterIndex;
    }
    var displayRoster = rosters[displayRosterIndex];

    let displayTotalRosterPoints = 0;
    // Total Points for a User
    if (displayRoster && displayRoster.players.length > 0) {
      displayTotalRosterPoints = displayRoster.players.map((scores, i) => {
        if(topx){
          if (i < topx){
            return scores.scores[0].points;
          }
          return 0;
        }
        return scores.scores[0].points;
      }).reduce((total, amount) => total + amount, 0);
    }

    if (league.blind_bid && league.league_status !== 'pre_draft') {
      displayTotalRosterPoints = displayTotalRosterPoints - displayRoster.pick_bid;
    }

    return {leagueRank: displayRosterIndex + 1, totalPoints: displayTotalRosterPoints, userHasRoster: userRosterIndex > -1};
  }

  confirmLeaveLeague(id) {
    this.setState({showLeaveLeague: true, leaveLeagueId: id});
  }

  leaveLeague() {
    this.props.leagueLeave(this.state.leaveLeagueId);
    this.setState({showLeaveLeague: false, leaveLeagueId: null});
  }

  declineLeaveLeague() {
    this.setState({showLeaveLeague: false, leaveLeagueId: null});
  }

  render() {
    const leagueBtnText = this.state.leagueBtnText;
    let Leagues = '';
    if (this.props.leagues && this.props.leagues.length > 0) {
      Leagues = this.props.leagues.map(league => {
        const rosterData = this.getRosterData(league);

        const pickInfo = league.rosters.map(y => {
          if (y.is_picking === true){
            return(
              <p key={y.id} className="text-xs"><i className="fa fa-exclamation-circle hover:text-red-800" aria-hidden="true"></i> {y.user.first_name + ' ' + y.user.last_name + 's Pick'}</p>
            );
          } return null;
        });

        return(


          <div key={league.id} className="flex rounded-sm bg-white hover:bg-opacity-75">
            <Link to={'/leagues/' + league.id} className='flex-grow'>
              <div className="p-2">
                <h3 className="whitespace-nowrap font-semibold text-lg">{league.name}</h3>
                <p className="text-xs"><span className="font-semibold">Status: </span>
                  {(league.league_status === 'post_draft' && 'Post Draft')
                || (league.league_status === 'pre_draft' && 'Pre Draft')
                || (league.league_status === 'draft' && 'In Draft')}
                  <span className="ml-2 font-semibold">Season:</span> {league.season}
                </p>
                {pickInfo}

              </div>
            </Link>
            <div className="relative mt-auto right-0 bottom-0">
              {rosterData.userHasRoster && <button onClick={() => this.confirmLeaveLeague(league.id)} className="flex-initial align-right pr-1 z-50"><i className="fa fa-sign-out text-red-800" aria-hidden="true"></i></button>}
              { league.owner === this.props.currentUser.id &&
                  <Link to='#' type="button" onClick={() => {this.showModal(league);}} className="flex-initial align-right pr-1"><i className="fa fa-cog hover:text-red-800" aria-hidden="true"></i></Link>
              }
            </div>
            <div className="ml-auto w-24 bg-darker-green text-white p-2 text-center">
              <div className="">
                {this.trophy(rosterData.leagueRank)}
              </div>
              <span className="font-semibold">{rosterData.totalPoints}</span><span className="text-xs"> PTS</span>
            </div>
          </div>

        );
      });
    }
    return (
      <React.Fragment>

        <div className="sm:px-4">
          <div className="text-center pt-12 pb-10">
            <h2 className="text-2xl text-white font-semibold mb-4">{this.props.leagues.length > 0 ? 'Your Leagues' : 'Get Started by creating a league.'}</h2>
            <button onClick={() => {this.showModal();}} className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded focus:outline-none">{leagueBtnText}</button>

            <LeageCreateForm
              createLeague={this.props.createLeague}
              editLeague={this.props.editLeague}
              deleteLeague={this.props.deleteLeague}
              onClose={this.showModal}
              show={this.state.show}
              league={this.state.leagueEditForm}
              currentUser={this.props.currentUser}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-5">
            {Leagues}
          </div>
          {this.state.showLeaveLeague && <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none text-black">
                  {/*header*/}
                  <div className="relative px-6 pt-3 flex-auto">
                    {'Are you sure you want to leave this league?'}
                  </div>
                  <button
                    className="bg-red-700 hover:bg-red-700 text-white font-bold py-2 px-4 m-5 mb-0 rounded"
                    onClick={this.leaveLeague}
                  >
                    {'Yes, Leave'}
                  </button>
                  <button
                    className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 m-5 rounded"
                    onClick={this.declineLeaveLeague}
                  >
                    {'No, Stay'}
                  </button>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
