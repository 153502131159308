import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

export default class ExpertBidForm extends Component {
  constructor(props){
    super(props);
    this.state = {
        bidAmount: this.props.currentBid,
        league: this.props.league,
    };

    this.setMinbid = this.setMinbid.bind(this);
    this.increaseBid = this.increaseBid.bind(this);
    this.decreaseBid = this.decreaseBid.bind(this);
  }

  setMinbid(e, setFieldValue) { 
    e.preventDefault();
    setFieldValue('bidAmount', this.props.currentBid + 500)
  }

  increaseBid(e, setFieldValue, bidAmount) {
    e.preventDefault();
    setFieldValue('bidAmount', bidAmount + 500)
  }

  decreaseBid(e, setFieldValue, bidAmount) {
    e.preventDefault();
    setFieldValue('bidAmount', Math.max(this.props.currentBid + 500, bidAmount - 500))
  }

  passBid(e, setFieldValue, submitForm) {
    e.preventDefault();
    setFieldValue('bidAmount', 0)
    submitForm();
  }

  render() {
    return (
    <div>
        <Formik
        initialValues={{bidAmount: this.props.currentBid + 500}}
        validate={values => {
            const errors = {};
            if (values.bidAmount < 0) {
                errors.bidAmount = 'Bid must be zero or greater.';
            }
            if (values.bidAmount == null || values.bidAmount === '') {
            errors.bidAmount = 'Required';
            }
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.props.submitExpertBid(
                this.props.league.id,
                this.props.roster.id,
                values.bidAmount,
            )
            setSubmitting(false);
        }}
        >
        {({ isSubmitting, setFieldValue, values, submitForm }) => (
            <Form className="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
                <h1 className="text-2xl text-center text-gray-900 mb-5">Bidding for pick #{this.props.league.bid_round}</h1>
                <div className="mb-6 flex items-center justify-center">
                    <label className="px-2">
                        <Field disabled name="bidAmount" className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline" id="pickAmount" type="number" placeholder="# Points to bid" />
                        <ErrorMessage className="text-red-500 text-xs italic" name='bidAmount' component="div" />
                    </label>
                </div>
                <div className="mb-10 flex items-center justify-center">
                    <label className="px-2">
                        <button onClick={(e) => this.setMinbid(e, setFieldValue)} className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline" type="submit">
                            min
                        </button>
                        <button 
                            onClick={(e) => this.decreaseBid(e, setFieldValue, values.bidAmount)} className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline" type="submit">
                            -500
                        </button>
                        <button onClick={(e) => this.increaseBid(e, setFieldValue, values.bidAmount)} className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 ml-2 rounded focus:outline-none focus:shadow-outline" type="submit">
                            +500
                        </button>
                    </label>
                </div>
                <div className="flex items-center justify-center">
                    <button className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Submit Bid
                    </button>
                    <button onClick={(e) => this.passBid(e, setFieldValue, submitForm)} className="bg-orange-700 hover:bg-dark-green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Pass
                    </button>
                </div>
            </Form>
        )}
        </Formik>
    </div>
    );
  }
}
