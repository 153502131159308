import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Scores from './containers/Scores/Scores';
import Dashboard from './containers/Dashboard';
import PlayerSelector from './components/PlayerSelector';
import Layout from './common/hoc/Layout/Layout';
import { GET_AUTH_TOKEN, GET_CURRENT_USER, CREATE_USER, SET_USER_LOADING, REMOVE_USER, ADD_FLASH_MESSAGE, GET_LEAGUES, SET_USER_SHOULD_LOGIN, REQUEST_RESET_PASSWORD } from './redux/actions/actionTypes';
import { haveTokens, removeTokens } from './utils/api';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faMedal, faUserFriends, faExchangeAlt, faPlus, faUserTimes, faCog, faExclamationCircle, faQuestionCircle, faUserPlus, faDirections, faFootballBall, faChevronUp, faChevronDown, faComment } from '@fortawesome/free-solid-svg-icons';
import FlashMessageList from './components/FlashMessage/FlashMessageList';
import PlayerLists from './components/PlayerLists';
import LeagueJoin from './components/LeagueJoin';
import Account from './components/Account';
import ResetPassword from './components/ResetPassword';


library.add(faCheckSquare, faMedal, faUserFriends, faExchangeAlt, faPlus, faUserTimes, faCog, faExclamationCircle, faQuestionCircle, faUserPlus, faDirections, faFootballBall, faChevronUp, faChevronDown, faComment );


const mapStateToProps = state => {
  return {
    user: state.user.user,
    userShouldLogin: state.user.userShouldLogin,
    loading: state.user.loading,
    leagues: state.leagues.leagues
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAuthToken: (email, password) => dispatch({type: GET_AUTH_TOKEN, payload: {email, password}}),
    createUser: (email, firstName, lastName, password) => dispatch({type: CREATE_USER, payload: {email, firstName, lastName, password}}),
    getCurrentUser: () => dispatch({type: GET_CURRENT_USER}),
    setUserLoading: (loading) => dispatch({type: SET_USER_LOADING, loading}),
    removeUser: () => dispatch({type: REMOVE_USER }),
    testFlashMessage: () => dispatch({type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'You have successfully logged in.'}}),
    logoutFlashMessage: () => dispatch({type: ADD_FLASH_MESSAGE, message: {type: 'success', text: 'You have successfully logged out'}}),
    signinErrorFlashMessage: () => dispatch({type: ADD_FLASH_MESSAGE, message: {type: 'fail', text: 'Your email or password are incorrect.'}}),
    getLeagues: () => dispatch({type: GET_LEAGUES}),
    setUserShouldLogin: (userShouldLogin) => dispatch({type: SET_USER_SHOULD_LOGIN, userShouldLogin}),
    resetPassword: (email) => dispatch({type: REQUEST_RESET_PASSWORD, payload: {email}}),
  };
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.logoutUser = this.logoutUser.bind(this);
  }

  componentDidMount() {
    if (haveTokens()) {
      // We've got tokens so let's try to load the user
      this.props.getCurrentUser();
    } else {
      // No tokens so just user loading to false
      this.props.setUserLoading(false);
    }
  }

  logoutUser(e) {
    this.props.removeUser();
    removeTokens();
    this.props.logoutFlashMessage();

    if (e) {
      e.preventDefault();
    }
  }

  render() {
    let loggedIn = this.props.user && this.props.user.id != null;
    if (this.props.loading) {
      return 'Loading';
    }
    if (loggedIn) {
      return (
        <React.Fragment>

          <FlashMessageList />
          <Header
            getLeagues={this.props.getLeagues}
            leagues={this.props.leagues}
            user={this.props.user}
            logoutUser={this.logoutUser}
          />
          <Layout>
            <Switch>
              <Route exact path="/user/profile" children={({match}) => <Account match={match} />} />
              <Route exact path="/leagues/:leagueCode/join" children={({match}) => <LeagueJoin match={match} />} />
              <Route exact path="/leagues/:leagueId/player-select" component={PlayerSelector} />
              <Route exact path="/leagues/:leagueId" children={({match}) => <Scores match={match} />} />
              <Route path="/" component={Dashboard} />
            </Switch>
          </Layout>
          <Footer />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <FlashMessageList />
          <Header
            getLeagues={this.props.getLeagues}
            user={this.props.user}
            leagues={this.props.leagues}
            logoutUser={this.logoutUser}
            getAuthToken={this.props.getAuthToken}
            createUser={this.props.createUser}
            userShouldLogin={this.props.userShouldLogin}
            setUserShouldLogin={this.props.setUserShouldLogin}
            resetPassword={this.props.resetPassword}
          />
          <Layout>
            <Switch>
              <Route exact path="/reset-password" children={({match}) => <ResetPassword match={match} />} />
              <Route exact path="/leagues/:leagueCode/join" children={({match}) => <LeagueJoin match={match} />} />
              <Route path="/" component={PlayerLists} />
            </Switch>
          </Layout>
          <Footer />
        </React.Fragment>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
