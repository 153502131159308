import React, { Component } from 'react';

class Layout extends Component {

  render () {
    return (
      <main className="container bg-darker-green bg-opacity-75 pb-10 sm:border-r-8 sm:border-l-8 sm:border-b-8 sm:border-white mx-auto max-w-screen-lg my-0 md:mb-6 sm:px-0">
        {this.props.children}
      </main>
    );
  }
}

export default Layout;