export const GET_LEAGUE = 'GET_LEAGUE';
export const GET_LEAGUES = 'GET_LEAGUES';
export const SET_LEAGUE = 'SET_LEAGUE';
export const SET_LEAGUES = 'SET_LEAGUES';
export const GET_ROSTERS = 'GET_ROSTERS';
export const SET_ROSTERS = 'SET_ROSTERS';
export const GET_PLAYERS = 'GET_PLAYERS';
export const SET_PLAYERS = 'SET_PLAYERS';
export const SET_ATP_PLAYERS = 'SET_ATP_PLAYERS';
export const SET_WTA_PLAYERS = 'SET_WTA_PLAYERS';
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const SET_USER_TOKENS = 'SET_USER_TOKENS';
export const LOAD_TOKENS = 'LOAD_TOKENS';
export const SET_USER = 'SET_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const REMOVE_USER = 'REMOVE_USER';
export const DROP_PLAYER = 'DROP_PLAYER';
export const ADD_PLAYER = 'ADD_PLAYER';
export const SWAP_PLAYERS = 'SWAP_PLAYERS';
export const CREATE_LEAGUE = 'CREATE_LEAGUE';
export const EDIT_LEAGUE = 'EDIT_LEAGUE';
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';
export const START_DRAFT = 'START_DRAFT';
export const SUBMIT_PICK_BID = 'SUBMIT_PICK_BID';
export const APPEND_ATP_PLAYERS = 'APPEND_ATP_PLAYERS';
export const APPEND_WTA_PLAYERS = 'APPEND_WTA_PLAYERS';
export const APPEND_PLAYERS = 'APPEND_PLAYERS';
export const DELETE_LEAGUE = 'DELETE_LEAGUE';
export const CREATE_USER = 'CREATE_USER';
export const SET_USER_SHOULD_LOGIN = 'SET_USER_SHOULD_LOGIN';
export const LEAGUE_JOIN = 'LEAGUE_JOIN';
export const SET_LEAGUE_JOIN = 'SET_LEAGUE_JOIN';
export const LEAGUE_LEAVE = 'LEAGUE_LEAVE';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const REVERSE_PICK = 'REVERSE_PICK';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SUBMIT_EXPERT_BID = 'SUBMIT_EXPERT_BID';