import React from 'react';
import { withRouter } from 'react-router-dom';
import './leaguecreateform.css';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';
import AlertDialog from '../AlertDialog/AlertDialog';

import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class LeageCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      advancedModal: false,
    };
    this.showConfirm = this.showConfirm.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.advancedHandler = this.advancedHandler.bind(this);
    this.bid_selection_types = ['blind_bid', 'blind_bid_ebay'];
  }

  showConfirm = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      confirmModal: !this.state.confirmModal,
    });
  };

  onConfirm = (confirm) => {
    if (confirm) {
      this.props.deleteLeague(this.props.league.id);
    }
    this.showConfirm();
  };

  onClose = (e) => {
    this.props.onClose && this.props.onClose(e);
  };

  advancedHandler = () => {
    this.setState({ advancedModal: !this.state.advancedModal });
  };

  submitLeague(data) {
    var formattedData = {
      ...data,
      roster_change_windows: [
        ...data.pickupDates
          .filter((w) => Object.keys(w).length !== 0)
          .map((window) => ({
            window_type: 'pickup',
            start: window.startDate.toISOString().slice(0, 10),
            end: window.endDate.toISOString().slice(0, 10),
            atp_limit: window.atp_limit,
            wta_limit: window.wta_limit,
            any_limit: window.any_limit,
          })),
        ...data.swapDates
          .filter((w) => Object.keys(w).length !== 0)
          .map((window) => ({
            window_type: 'swap',
            start: window.startDate.toISOString().slice(0, 10),
            end: window.endDate.toISOString().slice(0, 10),
            atp_limit: window.atp_limit,
            wta_limit: window.wta_limit,
            any_limit: window.any_limit,
          })),
      ],
      atp_players: data.league_orgs.includes('ATP'),
      wta_players: data.league_orgs.includes('WTA'),
      player_selection: data.player_selection,
      top_x: data.top_x || null,
    };
    if (this.props.league && this.props.league.id) {
      this.props.editLeague(this.props.league.id, formattedData);
    } else {
      this.props.createLeague(formattedData);
    }
  }

  randomizePickNumber(rosters, arrayHelpers) {
    const rosterRand = [...Array(rosters.length).keys()];
    for (let i = rosterRand.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [rosterRand[i], rosterRand[j]] = [rosterRand[j], rosterRand[i]];
    }
    rosters.map((roster, index) => {
      roster.pick_index = rosterRand[index] + 1;
      return arrayHelpers.replace(index, roster);
    });
  }

  gotoJoinLeague(code) {
    this.props.history.push('/leagues/' + code + '/join');
  }

  canChoosePickOrder(values) {
    return !(
      !this.bid_selection_types.includes(values.player_selection) && 
      values.draft_form !== 'expert' &&
      values.rosters.length > 0
    )
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    let leagueDisabledEdit = false;
    if (
      this.props.league !== undefined &&
      this.props.league.league_status !== 'pre_draft'
    ) {
      leagueDisabledEdit = true;
    }

    let leagueButtonEdit = '';
    if (this.props.league !== undefined) {
      leagueButtonEdit = 'Save';
    } else {
      leagueButtonEdit = 'Create League';
    }

    let userDraftValues = [];
    if (this.props.league !== undefined) {
      this.props.league.rosters.map((x) => {
        return userDraftValues.push({
          email: x.user.email,
          pick_index: x.pick_index,
        });
      });
    }
    let swapDraftValues = [];
    let pickupDraftValues = [];
    if (this.props.league !== undefined) {
      this.props.league.roster_change_windows.map((x) => {
        if (x.window_type === 'swap') {
          swapDraftValues.push({
            atp_limit: x.atp_limit,
            endDate: new Date(x.end),
            startDate: new Date(x.start),
            wta_limit: x.wta_limit,
            any_limit: x.any_limit,
          });
        }
        if (x.window_type === 'pickup') {
          pickupDraftValues.push({
            atp_limit: x.atp_limit,
            endDate: new Date(x.end),
            startDate: new Date(x.start),
            wta_limit: x.wta_limit,
            any_limit: x.any_limit,
          });
        }
        return null;
      });
    }

    let leagueOrgs = [];
    if (
      this.props.league !== undefined &&
      this.props.league.atp_players === true
    ) {
      leagueOrgs.push('ATP');
    }
    if (
      this.props.league !== undefined &&
      this.props.league.wta_players === true
    ) {
      leagueOrgs.push('WTA');
    }

    // Create Initial Draft Values for League creation or Editing
    let initialDraftValues = {};
    if (this.props.league === undefined) {
      initialDraftValues = {
        name: '',
        initial_roster_size: '',
        top_x: null,
        league_notes: "",
        swapDates: [],
        pickupDates: [],
        rosters: [],
        league_orgs: [],
        draft_form: 'expert',
        player_selection: 'custom',
      };
    } else {
      initialDraftValues = {
        name: this.props.league.name,
        initial_roster_size: this.props.league.initial_roster_size,
        top_x: this.props.league.top_x,
        league_notes: this.props.league.league_notes,
        swapDates: swapDraftValues,
        pickupDates: pickupDraftValues,
        rosters: userDraftValues,
        league_orgs: leagueOrgs,
        draft_form: this.props.league.draft_form,
        player_selection: this.props.league.player_selection,
      };
    }

    let leagueJoinUrl = '';
    let userInRoster = false;

    if (this.props.league) {
      leagueJoinUrl =
        process.env.REACT_APP_BASE_DOMAIN +
        '/leagues/' +
        this.props.league.league_code +
        '/join';
      userInRoster = this.props.league.rosters.find(
        (r) => r.user.id === this.props.currentUser.id
      );
    }

    return (
      <div className="w-full mt-4">
        <div className="bm-4 mx-auto max-w-xl max-h-full text-center">
          <Formik
            initialValues={initialDraftValues}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.initial_roster_size) {
                errors.initial_roster_size = 'Required';
              }
              if (
                values.top_x !== '' &&
                values.top_x != null &&
                values.top_x < 1
              ) {
                errors.top_x = 'Top X must be at least one.';
              }
              if (!values.draft_form) {
                errors.draft_form = 'Required';
              }
              if (values.league_orgs.length === 0) {
                errors.league_orgs = 'At least one required';
              }
              if (
                this.canChoosePickOrder(values)
              ) {
                var usedIndices = [];
                var invalidRosters = values.rosters.filter((r, i) => {
                  if (usedIndices.includes(r.pick_index)) {
                    return true;
                  }
                  usedIndices.push(r.pick_index);

                  return r.pick_index > values.rosters.length;
                });
                if (invalidRosters.length > 0) {
                  errors.rosters = 'Invalid pick number(s).';
                }
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              this.submitLeague(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4">
                {this.props.league === undefined && (
                  <h1 className="text-2xl text-gray-900 mb-5 ">
                    Create A League
                  </h1>
                )}
                {this.props.league !== undefined && (
                  <h1 className="text-2xl text-gray-900 mb-5 ">Edit League</h1>
                )}
                <div className="mb-6 mt-4">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      League Name
                    </span>
                  </h2>
                  <div className="mb-5 px-5 pb-2 pt-4 border-b border-t border-r border-l border-gray-400 rounded-md shadow appearance-none">
                    <Field
                      name="name"
                      className="shadow appearance-none border border-gray-400 rounded w-64 py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                      id="name"
                      type="text"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="name"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-6 mt-4">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      Initial Roster Size
                    </span>
                  </h2>
                  <div className="mb-5 px-5 pb-2 pt-4 border-b border-t border-r border-l border-gray-400 rounded-md shadow appearance-none">
                    <Field
                      name="initial_roster_size"
                      disabled={leagueDisabledEdit}
                      className="shadow appearance-none border border-gray-400 rounded w-64 py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                      id="initial_roster_size"
                      type="number"
                      placeholder=""
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="initial_roster_size"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-6 mt-4">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      Player Selection method
                      <i
                        className="fa fa-question-circle pl-1"
                        data-for="draft-method"
                        data-tip="Basic Draft - All rounds same order.<br /> Snake Draft - Draft order reverses after each round (recommended).<br /> Expert Draft - Draft picks are auctioned off one at a time."
                      ></i>
                    </span>
                  </h2>
                  <div className="mb-5 px-5 pb-2 pt-4 border-b border-t border-r border-l border-gray-400 rounded-md shadow appearance-none">
                    <Field
                      name="draft_form"
                      disabled={leagueDisabledEdit}
                      className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                      id="draft_form"
                      as="select"
                    >
                      <option value="expert">Expert Draft (Recommended)</option>
                      <option value="simple">Basic Draft</option>
                      <option value="snake">Snake Draft</option>
                    </Field>

                    <ReactTooltip id="draft-method" type="dark" multiline />
                  </div>
                </div>
                {values.draft_form != "expert" && <div className="mb-6 mt-4">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      Player Selection order
                      <i
                        className="fa fa-question-circle pl-1"
                        data-class="force-text-left"
                        data-for="player-selection"
                        data-tip="Custom [League creator will assign selection order manually or randomly]<br/><br/>
                        Blind Bid [League members all submit a secret bid with the highest bidder winning the 1st overall draft pick. The bid amount will be deducted from total team score.<br/>
                        Example:<br/>
                        * Team A bids 3000 points<br/>
                        * Team B bids 2000 points<br/>
                        * Team C bids 1000 points<br/>
                        Team A will draft 1st and will start the league with -3000 points, and so on…<br/><br/>
                        Ebay Bid [Same as the blind bid option, except bids will be automatically adjusted down to 1 point more than the next highest bid]<br/>
                        Example:<br/>
                        * Team A bids 3000 points<br/>
                        * Team B bids 2000 points<br/>
                        * Team C bids 1000 points<br/>
                        Team A will draft 1st and will start the league with -2001 points, Team B will be assigned -1001 points and will draft 2nd. Team C will be assigned -1 point and will draft 3rd.<br/>
                        "
                      ></i>
                    </span>
                  </h2>
                  <div className="px-5 pb-2 pt-4 border-b border-t border-r border-l border-gray-400 rounded-md shadow appearance-none">
                    <Field
                      name="player_selection"
                      disabled={leagueDisabledEdit}
                      className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                      id="player_selection"
                      as="select"
                    >
                      <option value="custom">Custom (Admin assigns)</option>
                      <option value="blind_bid">
                        Blind Bid (Regular Auction)
                      </option>
                      <option value="blind_bid_ebay">
                        Blind Bid (Ebay Auction)
                      </option>
                    </Field>

                    <ReactTooltip id="player-selection" type="dark" multiline />
                  </div>
                </div>}
                <div className="mb-4 mt-4">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      Player Organizations
                    </span>
                  </h2>
                  <div className="px-5 pb-2 pt-4 border-b border-t border-r border-l border-gray-400 rounded-md shadow appearance-none">
                    <label className="px-2">
                      <Field
                        type="checkbox"
                        name="league_orgs"
                        value="ATP"
                        className="mr-1"
                      />
                      ATP
                    </label>
                    <label className="px-2">
                      <Field
                        type="checkbox"
                        name="league_orgs"
                        value="WTA"
                        className="mr-1"
                      />
                      WTA
                    </label>
                  </div>
                  <ErrorMessage
                    className="text-red-500 text-xs italic"
                    name="league_orgs"
                    component="div"
                  />
                </div>
                {this.props.league && (
                  <div className="mb-6">
                    <h2 className="w-full text-center leading-1">
                      <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                        League Invite Link
                      </span>
                    </h2>
                    <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                      <Field
                        disabled={true}
                        className="mb-5 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        value={leagueJoinUrl}
                      />

                      {!userInRoster && !leagueDisabledEdit && (
                        <button
                          type="button"
                          className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          onClick={() =>
                            this.gotoJoinLeague(this.props.league.league_code)
                          }
                        >
                          Join
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {this.props.league && (
                  <div className="mb-6">
                    <h2 className="w-full text-center leading-1">
                      <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                        League Participants
                      </span>
                    </h2>
                    <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                      <FieldArray
                        name="rosters"
                        render={(arrayHelpers) => (
                          <div>
                            {values.rosters && values.rosters.length > 0 ? (
                              <div>
                                {values.rosters.map((roster, index) => (
                                  <div key={index}>
                                    <div className="flex mb-3 bg-light-green rounded p-3">
                                      <div className="flex-1 text-sm mb-2 pr-1">
                                        <label
                                          className="block text-gray-700 text-xs font-bold mb-1"
                                          htmlFor={`rosters.${index}.email`}
                                        >
                                          Email Address
                                        </label>
                                        <Field
                                          disabled={true}
                                          name={`rosters.${index}.email`}
                                          className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                          id={`user-${index}-email`}
                                          type="text"
                                          placeholder="Email Address"
                                        />
                                        <ErrorMessage
                                          className="text-red-500 text-xs italic"
                                          name={`rosters.${index}.email`}
                                          component="div"
                                        />
                                      </div>
                                      {!this.canChoosePickOrder(values) && (
                                        <div className="text-sm mb-2 w-1/3">
                                          <label
                                            className="block text-gray-700 text-xs font-bold mb-1"
                                            htmlFor={`rosters.${index}.pick_index`}
                                          >
                                            Pick Number
                                          </label>
                                          <Field
                                            disabled={leagueDisabledEdit}
                                            name={`rosters.${index}.pick_index`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`user-${index}-pick_index`}
                                            type="number"
                                            placeholder="Pick Number"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`rosters.${index}.pick_index`}
                                            component="div"
                                          />
                                        </div>
                                      )}
                                      <div className="flex-initial pt-6">
                                        <button
                                          type="button"
                                          disabled={leagueDisabledEdit}
                                          className="bg-darker-green hover:bg-dark-green align-middle text-white font-semibold rounded ml-1 px-2 pt-1 pb-1 text-xs focus:outline-none focus:shadow-outline"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          -
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {!this.canChoosePickOrder(values) && (
                                  <>
                                    <ErrorMessage
                                      className="text-red-500 text-xs italic mb-2"
                                      name="rosters"
                                      component="div"
                                    />
                                    <button
                                      type="button"
                                      disabled={leagueDisabledEdit}
                                      onClick={() =>
                                        this.randomizePickNumber(
                                          values.rosters,
                                          arrayHelpers
                                        )
                                      }
                                      className="text-sm mt-5 bg-transparent hover:bg-darker-green text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
                                    >
                                      <i className="fa fa-random fill-current w-4 h-4 mr-2"></i>
                                      Randomize Pick Order
                                    </button>
                                  </>
                                )}
                              </div>
                            ) : (
                              <p>No users have joined your league yet.</p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                )}

                <div className="mb-6">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      League Notes
                    </span>
                  </h2>
                  <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                    <Field
                      name="league_notes"
                      className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-left leading-tight focus:outline-none focus:shadow-outline"
                      id="league_notes"
                      type="text"
                      as="textarea"
                      placeholder="Maximum 1200 Characters"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="league_notes"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <h2 className="w-full text-center leading-1">
                    <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                      Advanced
                    </span>
                  </h2>
                  <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                    {this.state.advancedModal === true ? (
                      <div>
                        <div className="mx-auto w-64 mb-4 pl-1">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="top_x"
                          >
                            Top X
                            <i
                              className="fa fa-question-circle pl-1"
                              data-for="top_x"
                              data-tip="Top X allows you to limit total roster scores to only include the top X players in the roster."
                            ></i>
                          </label>
                          <ReactTooltip
                            id="top_x_tooltip"
                            type="dark"
                            multiline="true"
                          />
                          <Field
                            name="top_x"
                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                            id="top_x"
                            type="number"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name="top_x"
                            component="div"
                          />
                        </div>
                        <div className="my-6">
                          <h2 className="w-full text-center leading-1">
                            <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                              Swap Windows
                            </span>
                          </h2>
                          <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                            <FieldArray
                              name="swapDates"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.swapDates.map((_, index) => (
                                    <div
                                      className="mb-5 bg-light-green rounded p-3"
                                      key={index}
                                    >
                                      <div className="grid grid-cols-2 gap-1 md:grid-cols-2">
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold w-full"
                                            htmlFor={`swapDates.${index}.startDate`}
                                          >
                                            Start Date
                                          </label>
                                          <DatePicker
                                            selected={
                                              values.swapDates[index].startDate
                                            }
                                            dateFormat="yyyy-MM-dd"
                                            className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline w-full"
                                            name={`swapDates.${index}.startDate`}
                                            onChange={(date) =>
                                              setFieldValue(
                                                `swapDates.${index}.startDate`,
                                                date
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`swapDates.${index}.startDate`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold w-full"
                                            htmlFor={`swapDates.${index}.endDate`}
                                          >
                                            End Date
                                          </label>
                                          <DatePicker
                                            selected={
                                              values.swapDates[index].endDate
                                            }
                                            dateFormat="yyyy-MM-dd"
                                            className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline w-full"
                                            name={`swapDates.${index}.endDate`}
                                            onChange={(date) =>
                                              setFieldValue(
                                                `swapDates.${index}.endDate`,
                                                date
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`swapDates.${index}.endDate`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-1">
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`swapDates.${index}.atp_limit`}
                                          >
                                            # ATP Swaps
                                          </label>
                                          <Field
                                            name={`swapDates.${index}.atp_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`swapDates.${index}.atp_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`swapDates.${index}.atp_limit`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`swapDates.${index}.wta_limit`}
                                          >
                                            # WTA Swaps
                                          </label>
                                          <Field
                                            name={`swapDates.${index}.wta_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`swapDates.${index}.wta_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`swapDates.${index}.wta_limit`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`swapDates.${index}.any_limit`}
                                          >
                                            # Any League
                                          </label>
                                          <Field
                                            name={`swapDates.${index}.any_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`swapDates.${index}.any_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`swapDates.${index}.any_limit`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      {/* Remove Swap Date from list */}
                                      <div className="pt-12 md:pt-6">
                                        <button
                                          type="button"
                                          className="align-middle ml-1 px-2 pt-1 pb-1 text-sm"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} className="ml-1 mr-1" style={{ color: 'red' }} />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.push('')}
                                    className="text-sm bg-transparent hover:bg-darker-green text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
                                  >
                                    Add Swap Window
                                  </button>
                                  <div></div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-6">
                          <h2 className="w-full text-center leading-1">
                            <span className="bg-white px-3 text-gray-700 text-sm font-bold">
                              Pickup Windows
                            </span>
                          </h2>
                          <div className="px-5 pb-5 pt-4 shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline">
                            <FieldArray
                              name="pickupDates"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.pickupDates.map((_, index) => (
                                    <div
                                      className="mb-5 bg-light-green rounded p-3"
                                      key={index}
                                    >
                                      <div className="grid grid-cols-2 gap-1 md:grid-cols-2">
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold w-full"
                                            htmlFor={`pickupDates.${index}.startDate`}
                                          >
                                            Start Date
                                          </label>
                                          <DatePicker
                                            selected={
                                              values.pickupDates[index]
                                                .startDate
                                            }
                                            dateFormat="yyyy-MM-dd"
                                            className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline w-full"
                                            name={`pickupDates.${index}.startDate`}
                                            onChange={(date) =>
                                              setFieldValue(
                                                `pickupDates.${index}.startDate`,
                                                date
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`pickupDates.${index}.startDate`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold w-full"
                                            htmlFor={`swapDates.${index}.endDate`}
                                          >
                                            End Date
                                          </label>
                                          <DatePicker
                                            selected={
                                              values.pickupDates[index].endDate
                                            }
                                            dateFormat="yyyy-MM-dd"
                                            className="shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline w-full"
                                            name={`pickupDates.${index}.endDate`}
                                            onChange={(date) =>
                                              setFieldValue(
                                                `pickupDates.${index}.endDate`,
                                                date
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`pickupDates.${index}.endDate`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      <div className="grid grid-cols-2 md:grid-cols-3 gap-1">
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`pickupDates.${index}.atp_limit`}
                                          >
                                            # ATP Pickups
                                          </label>
                                          <Field
                                            name={`pickupDates.${index}.atp_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`pickupDates.${index}.atp_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`pickupDates.${index}.atp_limit`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`pickupDates.${index}.wta_limit`}
                                          >
                                            # WTA Pickups
                                          </label>
                                          <Field
                                            name={`pickupDates.${index}.wta_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`pickupDates.${index}.wta_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`pickupDates.${index}.wta_limit`}
                                            component="div"
                                          />
                                        </div>
                                        <div className="text-sm p-2">
                                          <label
                                            className="block text-gray-700 text-xs mb-1 font-bold"
                                            htmlFor={`pickupDates.${index}.any_limit`}
                                          >
                                            # Any League
                                          </label>
                                          <Field
                                            name={`pickupDates.${index}.any_limit`}
                                            className="shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
                                            id={`pickupDates.${index}.any_limit`}
                                            type="text"
                                            placeholder="0"
                                          />
                                          <ErrorMessage
                                            className="text-red-500 text-xs italic"
                                            name={`pickupDates.${index}.any_limit`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      {/* Remove Swap Date from list */}
                                      <div className="pt-12 md:pt-6">
                                        <button
                                          type="button"
                                          className="align-middle ml-1 px-2 pt-1 pb-1 text-sm"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} className="ml-1 mr-1" style={{ color: 'red' }} />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.push('')}
                                    className="text-sm bg-transparent hover:bg-darker-green text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
                                  >
                                    {/* show this when user has removed all Swap dates from the list */}
                                    Add Pickup Window
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <button
                      type="button"
                      onClick={this.advancedHandler}
                      className="text-sm bg-transparent hover:bg-darker-green text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded focus:outline-none"
                    >
                      {this.state.advancedModal === true ? (
                        <div>
                          Show Less<i className="fa fa-arrow-up pl-1"></i>
                        </div>
                      ) : (
                        <div>
                          Show More<i className="fa fa-arrow-down pl-1"></i>
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <button
                    disabled={isSubmitting}
                    className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {leagueButtonEdit}
                  </button>
                  {this.props.league !== undefined && (
                    <button
                      onClick={this.showConfirm}
                      className="bg-red-700 hover:bg-red-600 text-white font-bold ml-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="delete"
                    >
                      Delete
                    </button>
                  )}
                  {this.state.confirmModal ? (
                    <AlertDialog
                      title="Delete League"
                      confirm="Delete"
                      description={`Are you sure you want to delete ${this.props.league.name}`}
                      onConfirm={this.onConfirm}
                    />
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(LeageCreateForm);
