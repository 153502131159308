import { SET_USER, SET_USER_LOADING, REMOVE_USER, SET_USER_SHOULD_LOGIN } from '../actions/actionTypes';

const initialState = {loading: true, userShouldLogin: false};

const setUser = (state, action) => {
  return {...state, user: action.payload};
};

const setLoading = (state, action) => {
  return {...state, loading: action.loading};
};

const setUserShouldLogin = (state, action) => {
  return {...state, userShouldLogin: action.userShouldLogin};
};

const removeUser = (state) => {
  return {...state, user: null};
};

const user = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_USER) {
    return setUser(state, action);
  }

  if (action.type === SET_USER_LOADING) {
    return setLoading(state, action);
  }

  if (action.type === REMOVE_USER) {
    return removeUser(state);
  }

  if (action.type === SET_USER_SHOULD_LOGIN) {
    return setUserShouldLogin(state, action);
  }
  // For now, don't handle any actions
  // and just return the state given to us.
  return state;
};

export default user;