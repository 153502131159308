import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScoreCard from '../../components/ScoreCard/ScoreCard';
import { DROP_PLAYER, GET_LEAGUE, START_DRAFT, SUBMIT_PICK_BID, REVERSE_PICK, SUBMIT_EXPERT_BID } from '../../redux/actions/actionTypes';
import PlayerSelector from '../../components/PlayerSelector';
import LeagueNotifications from '../../components/LeagueNotifications/LeagueNotifications';

const mapStateToProps = (state) => {
  return {
    league: state.league,
    atp_players: state.players.atp_players,
    wta_players: state.players.wta_players,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLeague: (id) => dispatch({ type: GET_LEAGUE, payload: id }),
    dropPlayer: (player_id, roster, league_id) =>
      dispatch({
        type: DROP_PLAYER,
        payload: { player_id, roster, league_id },
      }),
    startDraft: (league) => dispatch({ type: START_DRAFT, payload: league }),
    submitPickBid: (leagueId, rosterId, pick_bid) => dispatch({ type: SUBMIT_PICK_BID, payload: {leagueId, rosterId, pick_bid} }),
    submitExpertBid: (leagueId, rosterId, bidAmount) => dispatch({ type: SUBMIT_EXPERT_BID, payload: {leagueId, rosterId, bidAmount} }),
    reversePick: (league) => dispatch({ type: REVERSE_PICK, payload: league }),
  };
};

class Scores extends Component {
  constructor(props) {
    super(props);
    this.state = { showPlayerSelector: false, showLeader: true, pickingRoster: null, rankWithBids: true};
    this.topTenToggleClick = this.topTenToggleClick.bind(this);
    this.leaderToggleClick = this.leaderToggleClick.bind(this);
    this.pickBidsPending = this.pickBidsPending.bind(this);
    this.toggleRankWithBids = this.toggleRankWithBids.bind(this);
  }

  componentDidMount() {
    this.props.getLeague(this.props.match.params.leagueId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.leagueId !== prevProps.match.params.leagueId) {
      this.props.getLeague(this.props.match.params.leagueId);
    }
  }

  leaderToggleClick() {
    this.setState({ showPlayerSelector: false, showLeader: true });
  }

  topTenToggleClick(roster) {
    this.setState({ showPlayerSelector: true, showLeader: false, pickingRoster: roster});
  }

  currentPicker() {
    return this.props.league.rosters.find(r => r.is_picking);
  }

  canEditDraft() {
    return this.props.user.user && (this.props.user.user.id === this.props.league.owner || this.props.user.user.is_staff);
  }

  pickBidsPending() {
    return this.props.league.blind_bid && this.props.league.rosters.findIndex(r => r.pick_bid == null) !== -1;
  }

  toggleRankWithBids(e) {
    this.setState({rankWithBids: !this.state.rankWithBids});
  }

  canReversePick() {
    let currentPicker = this.currentPicker();
    if (this.props.league.draft_form === 'expert') {
      return false;
    }
    let pickOneRoundOne = this.props.league.draft_round === 1 && currentPicker.pick_index === 1;
    return !pickOneRoundOne && this.canEditDraft() && this.props.league.league_status === 'draft';
  }

  render() {
    let Cards = 'Loading';

    if (this.props.league && this.props.league.id) {
      let topx = this.props.league.top_x;

      Cards = this.props.league.rosters
        .sort((rosterA, rosterB) => {

          // Sort by bid index for expert bids during draft
          if (this.props.league.draft_form == 'expert' && this.props.league.league_status == 'draft') {
            return rosterA.bid_index - rosterB.bid_index;
          }
          // Don't sort by scores if we're not past draft
          if (this.props.league.league_status === 'pre_draft' ||
              this.props.league.league_status === 'draft') {
            return rosterA.pick_index - rosterB.pick_index;
          }

          let playersA = rosterA.players;
          let playersB = rosterB.players;

          if (topx) {
            playersA = playersA.slice(0, topx);
            playersB = playersB.slice(0, topx);
          }
          var aTotal = playersA.reduce(
            (total, p) => total + p.scores[0].points,
            0
          );
          var bTotal = playersB.reduce(
            (total, p) => total + p.scores[0].points,
            0
          );
          if (this.props.league.blind_bid && this.state.rankWithBids) {
            aTotal = aTotal - rosterA.pick_bid;
            bTotal = bTotal - rosterB.pick_bid;
          }

          if (this.props.league.draft_form == 'expert' && this.state.rankWithBids) {
            aTotal = aTotal - rosterA.draft_round_bid;
            bTotal = bTotal - rosterB.draft_round_bid;
          }

          // For tie breakers we give the win to roster with the highest top pick.
          if (bTotal === aTotal && rosterA.players.length > 0 && rosterB.players.length > 0) {
            aTotal += rosterA.players[0].scores[0].points;
            bTotal += rosterB.players[0].scores[0].points;
          }
          return bTotal - aTotal;
        })
        .map((roster, i) => (
          <ScoreCard
            dropPlayer={this.props.dropPlayer}
            currentUser={this.props.user.user}
            key={roster.id}
            index={i}
            roster={roster}
            user={roster.user}
            league={this.props.league}
            topTenToggleClick={this.topTenToggleClick}
            submitPickBid={this.props.submitPickBid}
            submitExpertBid={this.props.submitExpertBid}
            pickBidsPending={this.pickBidsPending}
            rankWithBids={this.state.rankWithBids}
          />
        ));
    }

    return (
      <React.Fragment>
        <div className="text-center pt-12 pb-2">
          <h1 className="text-2xl text-white">{this.props.league.name}</h1>
          <LeagueNotifications league={this.props.league} pickBidsPending={this.pickBidsPending} currentPicker={this.currentPicker}  />

          {this.canEditDraft() && this.props.league.league_status === 'pre_draft' &&
            <button
              disabled={this.pickBidsPending()}
              onClick={() => this.props.startDraft(this.props.league)}
              className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded mr-2"
            >
              Start Draft
            </button>
          }
          <button
            onClick={() => this.leaderToggleClick()}
            className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded mr-2"
          >
          Leaderboard
          </button>
          {(this.props.league.league_status === 'draft' || this.props.league.league_status === 'post_draft') &&
          <button
            onClick={() => this.topTenToggleClick()}
            className="bg-darker-green hover:bg-dark-green text-white font-bold py-2 px-4 rounded"
          >
            Free Agents
          </button>
          }
          {this.state.showLeader && (this.props.league.blind_bid || this.props.league.draft_form == 'expert') && this.props.league.league_status === 'post_draft' &&
            <label htmlFor="toogleA" className="justify-center sm:justify-end sm:mr-5 mt-5 flex items-center cursor-pointer pl-5">
              <div className="relative">
                <input id="toogleA" type="checkbox" className="hidden" checked={this.state.rankWithBids} onChange={this.toggleRankWithBids} />
                <div className="toggle__line w-10 h-6 bg-gray-400 rounded-full shadow-inner" style={{height: '1rem'}}></div>
                <div className="toggle__dot absolute w-6 bg-white rounded-full shadow inset-y-0 left-0" style={{height: '1.5rem'}}></div>
              </div>
              <div className="ml-3 text-white font-medium">
                Rank with Bids
              </div>
            </label>
          }
          {this.canReversePick() &&
            <button
              onClick={() => this.props.reversePick(this.props.league)}
              className="bg-red-700 hover:bg-red-600 text-white font-bold ml-2 mb-2 py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
            >
              Reverse Last Pick
            </button>
          }
        </div>

        <div className="flex flex-wrap justify-center">
          {this.state.showLeader ? Cards : ''}
          {this.state.showPlayerSelector ? (
            <PlayerSelector
              league={this.props.league}
              pickingRoster={this.state.pickingRoster}
              currentUser={this.props.user.user}
            />
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scores);
