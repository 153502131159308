import { SET_LEAGUE_JOIN } from '../actions/actionTypes';


const initialState = {
  loading: true,
  success: false,
};

const setLeagueJoin = (state, action) => {
  return {...state, ...action.payload};
};

const leagueJoin = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === SET_LEAGUE_JOIN) {
    return setLeagueJoin(state, action);
  }

  // For now, don't handle any actions
  // and just return the state given to us.
  return state;
};

export default leagueJoin;