import { all } from 'redux-saga/effects';
import {
  watchGetLeague,
  watchGetLeagues,
  watchGetPlayers,
  watchGetTokens,
  watchGetUser,
  watchCreateUser,
  watchDropPlayer,
  watchAddPlayer,
  watchSwapPlayers,
  watchCreateLeague,
  watchEditLeague,
  watchStartLeague,
  watchSubmitPickBid,
  watchDeleteLeague,
  watchLeagueJoin,
  watchLeagueLeave,
  watchDeleteAccount,
  watchUpdateAccount,
  watchReversePick,
  watchRequestResetPassword,
  watchResetPassword,
  watchSubmitExpertBid
} from './sagas';


export default function* rootSaga() {
  yield all([
    watchGetLeague(),
    watchGetLeagues(),
    watchGetPlayers(),
    watchGetTokens(),
    watchGetUser(),
    watchCreateUser(),
    watchDropPlayer(),
    watchAddPlayer(),
    watchSwapPlayers(),
    watchCreateLeague(),
    watchEditLeague(),
    watchStartLeague(),
    watchSubmitPickBid(),
    watchDeleteLeague(),
    watchLeagueJoin(),
    watchLeagueLeave(),
    watchDeleteAccount(),
    watchUpdateAccount(),
    watchReversePick(),
    watchRequestResetPassword(),
    watchResetPassword(),
    watchSubmitExpertBid(),
  ]);
}