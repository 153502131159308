import axios from 'axios';


export const TOKENS_STORAGE_KEY = 'fantasytennisauth';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const haveTokens = () => {
  if (localStorage.getItem(TOKENS_STORAGE_KEY)) {
    return true;
  }
  return false;
};

export const removeTokens = () => {
  localStorage.removeItem(TOKENS_STORAGE_KEY);
};

async function refreshAccessToken() {
  let tokens = JSON.parse(localStorage.getItem(TOKENS_STORAGE_KEY));
  var accessToken = await api.post('/token/refresh/', {refresh: tokens.refresh})
    .then((response) => {
      return response.data.access;
    });

  // console.log(accessToken);
  tokens.access = accessToken;
  localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));
  return accessToken;
}

api.interceptors.request.use(function (config) {
  let tokens = localStorage.getItem(TOKENS_STORAGE_KEY);

  if (tokens) {
    tokens = JSON.parse(tokens);
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + tokens.access
    };
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && originalRequest.url === '/token/refresh/') {
    localStorage.removeItem(TOKENS_STORAGE_KEY);
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/token/refresh/' && originalRequest.url !== '/token/') {
    const accessToken = await refreshAccessToken();

    if (!accessToken) {
      localStorage.removeItem(TOKENS_STORAGE_KEY);
      return Promise.reject(error);
    }

    originalRequest._retry = true;
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: 'Bearer ' + accessToken
    };

    return api(originalRequest);
  }
  return Promise.reject(error);
});
